<template>
  <div id="partners">
    <b-container class="partnerSuppler_block">
      <b-row v-for="(spon, index) in part" :key="index" class="spRow">
        
        <b-col class="logoBack" lg="4">
          <a :href='spon.acf.url' class="noUnderline">
            <div class="partSec">
              <b-img class="logo" :src="spon.acf.logo"></b-img>
            </div>
          </a>
        </b-col>
        <b-col class="textBack" lg="8">
          <a :href='spon.acf.url' class="noUnderline">
            <div class="textSec">
              <h3 class="labelField">
                {{ spon.acf.label }}
              </h3>
              <p></p>
              <span class='white' v-html="spon.acf.text"></span>
            </div>
          </a>
        </b-col>
        
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "partners",
  data() {
    return {
      part: [],
    };
  },
  computed: {
    setDev: function() {
      return process.env.VUE_APP_WPAPI_URL+"wp/v2/tourpartners";
    },
  },
  mounted() {
    axios
      .get(this.setDev + "?randomadd=" + new Date().getTime())
      .then((response) => {
        this.part = response.data;
      });
  },
};
</script>

<style scoped>
.noUnderline:hover {
  text-decoration: none;
}
.partnerSuppler_block {
  padding-bottom: 75px;
}
#partners {
  background-color: #f1f1f1;
}
.logo {
  width: 100%;
}
.logoBack {
  background-color: white;
}
.partSec {
  margin-top: 2em;
  text-align: left;
  padding-bottom: 0px;
  margin-right: 10px;
  margin-bottom: 2em;
}
.spRow {
  margin: auto;
  padding: 75px 0 0;
}
.textBack {
  background-color: #01477b;
  color: #ffffff;
}
.textSec {
  position: initial;
  top: 0;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
}
.labelField {
  font-size: 17pt;
  margin-top: 0;
  margin-bottom: 0;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
}
.labelField:hover {
  font-size: 17pt;
  margin-top: 0;
  margin-bottom: 0;
  color: #42a7c6;
  font-weight: 400;
  cursor: pointer;
}
.textSec > p {
  color: #ffffff;
  font-size: 16px;
  text-transform: initial;
  font-family: Helvetica, Arial !important;
  line-height: 32px;
}
::v-deep .white > p {
  color: #ffffff!important;
  font-weight: 300;
  line-height: 1.7;
}


@media only screen and (max-width: 425px) {
  .spRow {
    margin: auto;
    padding: 45px 0 0;
  }
  ::v-deep .white > p {
    color: #ffffff!important;
    font-weight: 300;
    line-height: 1.7;
    padding-bottom: 10px;
  }
}
</style>
