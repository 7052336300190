import { render, staticRenderFns } from "./hbh.vue?vue&type=template&id=67a81049&scoped=true"
import script from "./hbh.vue?vue&type=script&lang=js"
export * from "./hbh.vue?vue&type=script&lang=js"
import style0 from "./hbh.vue?vue&type=style&index=0&id=67a81049&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67a81049",
  null
  
)

export default component.exports