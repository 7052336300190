<template>
  <div>
    <span v-if="this.$route.name === 'home' || this.$route.name === 'Tournament'">
    <a class="twitter-timeline" data-height="571" :href="'https://twitter.com/'+config.VUE_APP_TWITTER_NAME">Posts by LETgolf</a> <script type="application/javascript" async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    </span>
    <span v-if="this.$route.name === 'team-europe'">
      <a class="twitter-timeline" data-height="571" :href="'https://twitter.com/'+config.VUE_APP_TWITTER_SOL+'?ref_src=twsrc%5Etfw'">Pots by SolheimCupEuro</a> <script type="application/javascript" async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    </span>
    </div>
</template>

<script>
export default {
  name: "twitterFeed",
  props:['develop', 'config']
};
</script>

<style scoped>
#twitter-widget-0{
  height: 500px;
}
</style>
