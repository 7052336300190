<template>
  <div>
    <b-row class="instaRow">
      <b-col
        lg="4"
        md="3"
        v-for="(insta, index) in insta.data.slice(0,15)"
        :key="index"
        class="instaHeight"
      >
        <a :href="insta.permalink" target="_blank" rel="noopener" class="instaPic">
          <template v-if="insta.media_type === 'VIDEO'">
            <div class="instaInner-pic" :style="'background-image:url('+insta.thumbnail_url +');'">
              <font-awesome-icon
                id="fb"
                class="playCircle"
                :icon="['fa', 'play-circle']"
              />
            </div>      
          </template>
          <template v-else>
            <div class="instaInner-pic" :style="'background-image: url('+insta.media_url+');'">
            </div>            
          </template>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "instafeed",
  props:['config'],
  data() {
    return {
      insta: [],
    };
  },
  computed: {
    instaPlay(url) {
      if (this.$route.name === 'team-europe') {
        url = "https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,thumbnail_url,caption&access_token=IGQVJYbUFfZA1NuUXd6T0tzb01NbkpvSXJNeVUyS1NxN1pOVGt5N3hXNkI1RlZAPTUNHanFab2R1NXNxZAnNlQUdTVDFqYU8wOVNRSjBPQ3p2ODJXUnI0RWo1N0VNeGNyOWJ0UGdUX2JR";
      } else {
        url = process.env.VUE_APP_INSTAGRAM_URL;
      }
      return url;
    }
  },
  mounted() {
    axios //add API Call
      .get(this.instaPlay) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.insta = response.data;
      });
  },
};
</script>

<style scoped>
.instRow {
  padding-left: 13px;
  padding-right: 13px;
}
.playCircle {
  font-size: 50px;
  color: #ffffff;
  margin-top: 30px;
}
.instaInner-pic {
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  background-color: #263056;
}
a.instaPic {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;
}

.instaHeight {
  height: 110px;
  overflow: hidden;
  padding: 2px;
  margin-bottom: 0;
}
.fa-gradient {
  color: #9c47fc;
  background: -webkit-linear-gradient(#9c47fc, #1e60e4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.row.instaRow {
  padding-left: 12px;
  padding-right: 12px;
}
@media only screen and (max-width: 2560px) {
  .instaHeight{
    height: 110px;
  }
}
@media only screen and (max-width: 1440px) {
  .instaHeight {
    height: 110px;
  }
  .playCircle {
    font-size: 50px;
    color: #ffffff;
    /* margin-top: 68px; */
  }
}
@media only screen and (max-width: 1024px) {

  .instaHeight{
    height: 115px;
  }
  .playCircle {
    font-size: 30px;
    color: #ffffff;
    margin-top: 42px;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .row.instaRow {
    padding-left: 13px!important;
    padding-right: 15px!important;
  }
}
@media only screen and (max-width: 480px) {
  .instaHeight{
    height: 300px;
  }
  .playCircle{
    margin-top: 100px;
    font-size: 60px;
  }
}
</style>
