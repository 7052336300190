<template>
  <div class="playerCard" :class="{cardHeight: data.stats_avail != 'N'}">
    <div id="desktop" class="table-responsive marginTop">
      <b-tabs content-class="mt-3" class="tabsConent">
        <b-tab :title="'Round ' + i " v-for="(i, index) in range(1, roundsPlayed)"
          :key="index"
          variant="primary"
          class="roundBtnColour"
          title-item-class="scorecardTabs orginal"
          @click="showRound(index)" active>
        </b-tab>
      </b-tabs>
      <table id="scoreCard">
        <thead>
          <tr class="t3">
            <th>
              Hole
            </th>
            <th>
              1
            </th>
            <th>
              2
            </th>
            <th>
              3
            </th>
            <th>
              4
            </th>
            <th>
              5
            </th>
            <th>
              6
            </th>
            <th>
              7
            </th>
            <th>
              8
            </th>
            <th>
              9
            </th>
            <th>
              OUT
            </th>
            <th>
              10
            </th>
            <th>
              11
            </th>
            <th>
              12
            </th>
            <th>
              13
            </th>
            <th>
              14
            </th>
            <th>
              15
            </th>
            <th>
              16
            </th>
            <th>
              17
            </th>
            <th>
              18
            </th>
            <th>
              IN
            </th>
            <th>
              TOTAL
            </th>
          </tr>
        </thead>

        <tbody >
          <template v-if="data.multi_course == 'Y'">
            <tr
              class="t3"
              :class="multi.course_X_colour + 'row'"
              v-for="(multi, index) in data.courses.courses_entry"
              :key="index"
            >
              <td>{{ multi.course_X_id }} Mtrs</td>
              <td
                v-for="(meter, index) in multi.course_X_length_meters
                  .split(',')
                  .slice(0, 9)"
                :key="index + Math.random()"
              >
                {{ meter }}
              </td>
              <td>
                {{ multi.course_X_out_length.slice(1, 6) }}
              </td>
              <td
                v-for="(meter, index) in multi.course_X_length_meters
                  .split(',')
                  .slice(9, 18)"
                :key="index + Math.random()"
              >
                {{ meter }}
              </td>
              <td>
                {{ multi.course_X_in_length.slice(1, 6) }}
              </td>
              <td>
                {{ multi.course_X_total_length.slice(1, 6) }}
              </td>
            </tr>
            <tr
              class="t3"
              :class="multi.course_X_colour + 'row'"
              v-for="(multi, index) in data.courses.courses_entry"
              :key="index"
            >
              <td>{{ multi.course_X_id }} Par</td>
              <td
                v-for="(par, index) in multi.course_X_par.split(',').slice(0, 9)"
                :key="index + Math.random()"
              >
                {{ par }}
              </td>
              <td>
                {{ multi.course_X_out_par.slice(1, 6) }}
              </td>
              <td
                v-for="(par, index) in multi.course_X_par.split(',').slice(9, 18)"
                :key="index + Math.random()"
              >
                {{ par }}
              </td>
              <td>
                {{ multi.course_X_in_par.slice(1, 6) }}
              </td>
              <td>
                {{ multi.course_X_total_par }}
              </td>
            </tr>
          </template>
          <template v-else>
            <tr class="t3" >
              <td>
                Yds
              </td>
              <td
                v-for="(yard, index) in data.course_length_yards
                  .split(',')
                  .slice(0, 9)"
                :key="index + Math.random()"
              >
                {{ yard }}
              </td>
              <td>
                {{ data.course_out_length.slice(1, 6) }}
              </td>
              <td
                v-for="(number, index) in data.course_length_yards
                  .split(',')
                  .slice(9, 18)"
                :key="index + Math.random()"
              >
                {{ number }}
              </td>
              <td>
                {{ data.course_in_length.slice(1, 6) }}
              </td>
              <td>
                {{ data.course_total_length.slice(1, 6) }}
              </td>
            </tr>
            <tr class="t3">
              <td>
                Par
              </td>
              <td
                v-for="(yard, index) in data.course_par.split(',').slice(0, 9)"
                :key="index + Math.random()"
              >
                {{ yard }}
              </td>
              <td>
                {{ data.course_out_par.slice(1, 6) }}
              </td>
              <td
                v-for="(number, index) in data.course_par.split(',').slice(9, 18)"
                :key="index + Math.random()"
              >
                {{ number }}
              </td>
              <td>
                {{ data.course_in_par.slice(1, 6) }}
              </td>
              <td>
                {{ data.course_total_par.slice(1, 6) }}
              </td>
            </tr>
          </template>
          <template>
            <template v-if="team == 'Y'">
              <tr class="RoundColour">
                <td>R{{ roundsPlayed }} <span v-if="data.multi_course == 'Y'" :class="playerCardData['course_colour_R' + roundsPlayed]">•</span></td>
                <template v-if="tmparams.stableford == 'N'">
                  <td
                    v-for="(r1, index) in playerCardData['team_scores_R' + roundsPlayed].split(',').slice(0, 9)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="
                      "
                      v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ outVal(playerCardData['team_scores_R' + roundsPlayed]) }}</td>
                  <td
                    v-for="(r1, index) in playerCardData['team_scores_R' + roundsPlayed].split(',').slice(9, 18)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="twoOver"
                      v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ inVal(playerCardData['team_scores_R' + roundsPlayed]) }}</td>
                  <td>{{ totalVal(playerCardData['team_scores_R' + roundsPlayed])}}</td>
                </template>
                <template v-else>
                  <td
                    v-for="(r1, index) in playerCardData['team_points_R' + roundsPlayed].split(',').slice(0, 9)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="twoOver"
                      v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ outVal(playerCardData['team_points_R' + roundsPlayed]) }}</td>
                  <td
                    v-for="(r1, index) in playerCardData['team_points_R' + roundsPlayed].split(',').slice(9, 18)"
                    :key="index + Math.random()"
                  >
                    <span
                      class="twoUnder"
                      v-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -2"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneUnder"
                      v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="oneOver"
                      v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == 1"
                    >
                      {{ r1 }}
                    </span>
                    <span
                      class="twoOver"
                      v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == 2"
                    >
                      {{ r1 }}
                    </span>
                    <span v-else>
                      {{ r1 }}
                    </span>
                  </td>
                  <td>{{ inVal(playerCardData['team_points_R' + roundsPlayed]) }}</td>
                  <td>{{ totalVal(playerCardData['team_points_R' + roundsPlayed])}}</td>
                </template>
              </tr>
            </template>
            <template v-else>
              <template v-if="data.stats_avail == 'Y'">
                <template>
                  <tr class="RoundColour">
                      
                    <template v-if="playerCardData['green_in_reg_R' + buttonRound]">
                      <td>R{{ buttonRound }} <span v-if="data.multi_course == 'Y'" :class="playerCardData['course_colour_R' + buttonRound]">•</span></td>
                    </template>
                    <template v-if="tmparams.stableford == 'N'">
                      <template v-if="playerCardData['green_in_reg_R' + buttonRound]">
                        <td
                          v-for="(r1, index) in playerCardData['score_R' + buttonRound].split(',').slice(0, 21)"
                          :key="index + Math.random()"
                        >
                          <span
                            :class="{twoUnderColor : index == '9' || index == '19' || index == '20'}"
                            class="twoUnder"
                            v-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == -2 || playerCardData['vspar_R' + buttonRound].split(',')[index] < -2"
                          >
                            {{ r1 }} 
                          </span>
                          <span
                            :class="{oneUnderColor : index == '9' || index == '19' || index == '20'}"
                            class="oneUnder"
                            v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == -1"
                          >
                            {{ r1 }}
                          </span>
                          <span
                            :class="{oneOverrColor : index == '9' || index == '19' || index == '20'}"
                            class="oneOver"
                            v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == 1"
                          >
                            {{ r1 }}
                          </span>
                          <span
                            :class="{twoOverColor : index == '9' || index == '19' || index == '20'}"
                            class="twoOver"
                            v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == 2 || playerCardData['vspar_R' + buttonRound].split(',')[index] > 2"
                          >
                            {{ r1 }}
                          </span>
                          <span v-else>
                            {{ r1 }}
                          </span>
                        </td>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="playerCardData['green_in_reg_R' + buttonRound]">
                        <td
                          v-for="(r1, index) in playerCardData['points_R' + buttonRound].split(',').slice(0, 21)"
                          :key="index + Math.random()"
                        >
                          <span
                            class="twoUnder"
                            v-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == -2"
                          >
                            {{ r1 }}
                          </span>
                          <span
                            class="oneUnder"
                            v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == -1"
                          >
                            {{ r1 }}
                          </span>
                          <span
                            class="oneOver"
                            v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == 1"
                          >
                            {{ r1 }}
                          </span>
                          <span
                            class="twoOver"
                            v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == 2"
                          >
                            {{ r1 }}
                          </span>
                          <span v-else>
                            {{ r1 }}
                          </span>
                        </td>
                      </template>
                    </template>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr class="RoundColour">
                    
                  <template >
                    <td>R{{ buttonRound }} <span v-if="data.multi_course == 'Y'" :class="playerCardData['course_colour_R' + buttonRound]">•</span></td>
                  </template>
                  <template v-if="tmparams.stableford == 'N'">
                    <template >
                      <td
                        v-for="(r1, index) in playerCardData['score_R' + buttonRound].split(',').slice(0, 21)"
                        :key="index + Math.random()"
                      >
                        <span
                          :class="{twoUnderColor : index == '9' || index == '19' || index == '20'}"
                          class="twoUnder"
                          v-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == -2 || playerCardData['vspar_R' + buttonRound].split(',')[index] < -2"
                        >
                          {{ r1 }} 
                        </span>
                        <span
                          :class="{oneUnderColor : index == '9' || index == '19' || index == '20'}"
                          class="oneUnder"
                          v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == -1"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          :class="{oneOverrColor : index == '9' || index == '19' || index == '20'}"
                          class="oneOver"
                          v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == 1"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          :class="{twoOverColor : index == '9' || index == '19' || index == '20'}"
                          class="twoOver"
                          v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == 2 || playerCardData['vspar_R' + buttonRound].split(',')[index] > 2"
                        >
                          {{ r1 }}
                        </span>
                        <span v-else>
                          {{ r1 }}
                        </span>
                      </td>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="playerCardData['green_in_reg_R' + buttonRound]">
                      <td
                        v-for="(r1, index) in playerCardData['points_R' + buttonRound].split(',').slice(0, 21)"
                        :key="index + Math.random()"
                      >
                        <span
                          class="twoUnder"
                          v-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == -2"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="oneUnder"
                          v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == -1"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="oneOver"
                          v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == 1"
                        >
                          {{ r1 }}
                        </span>
                        <span
                          class="twoOver"
                          v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == 2"
                        >
                          {{ r1 }}
                        </span>
                        <span v-else>
                          {{ r1 }}
                        </span>
                      </td>
                    </template>
                  </template>
                </tr>
              </template>
            </template>
            <template v-if="data.stats_avail == 'Y'">
              <template v-if="totalVal(playerCardData['green_in_reg_R' + buttonRound]) !== 0">
                <tr :key="roundIt1" v-if="playerCardData['drive_length_R' + buttonRound] > 0">
                  <td>D/D</td>
                  <td
                    v-for="(r1, index) in playerCardData['drive_length_R' + buttonRound]
                      .split(',')
                      .slice(0, 21)"
                    :key="index + Math.random()"
                  >
                    {{ r1 }}
                  </td>
                </tr>
                <tr :key="roundIt1" v-if="playerCardData['fairway_R' + buttonRound]">
                  <td>F/W</td>
                  <td
                    v-for="(r1, index) in playerCardData['fairway_R' + buttonRound]
                      .split(',')
                      .slice(0, 21)"
                    :key="index + Math.random()"
                  >
                    <span v-if="r1 === 'Y' || r1 === 'y'"
                      ><font-awesome-icon class="check" :icon="['fa', 'check']"
                    /></span>
                    <span v-else-if="r1 === 'N' || r1 === 'n'"
                      ><font-awesome-icon class="cross" :icon="['fa', 'times']"
                    /></span>
                    <span v-else>{{ r1 }}</span>
                  </td>
                </tr>
                <tr>
                  <td>GiR</td>
                  <td
                    v-for="(r1, index) in playerCardData['green_in_reg_R' + buttonRound]
                      .split(',')
                      .slice(0, 21)"
                    :key="index + Math.random()"
                  >
                    <span v-if="r1 === 'Y' || r1 === 'y'"
                      ><font-awesome-icon class="check" :icon="['fa', 'check']"
                    /></span>
                    <span v-else-if="r1 === 'N' || r1 === 'n'"
                      ><font-awesome-icon class="cross" :icon="['fa', 'times']"
                    /></span>
                    <span v-else>{{ r1 }}</span>
                  </td>
                </tr>
                <tr :key="roundIt1" v-if="playerCardData['sand_save_R' + buttonRound] > 0">
                  <td>S/S</td>
                  <td
                    v-for="(r1, index) in playerCardData['sand_save_R' + buttonRound]
                      .split(',')
                      .slice(0, 21)"
                    :key="index + Math.random()"
                  >
                    <span v-if="r1 === 'Y' || r1 === 'y'"
                      ><font-awesome-icon class="check" :icon="['fa', 'check']"
                    /></span>
                    <span v-else-if="r1 === 'N' || r1 === 'n'"
                      ><font-awesome-icon class="cross" :icon="['fa', 'times']"
                    /></span>
                    <span v-else>{{ r1 }}</span>
                  </td>
                  <td>{{ StatoutVal(playerCardData['sand_save_R' + buttonRound], 'front') }}</td>
                </tr>
                <tr :key="roundIt1">
                  <td>Putts</td>
                  <td
                    v-for="(r1, index) in playerCardData['putts_R' + buttonRound]
                      .split(',')
                      .slice(0, 21)"
                    :key="index + Math.random()"
                  >
                    {{ r1 }}
                  </td>
                </tr>
              </template>
              <template v-else>

              </template>
            </template>
          </template>
        </tbody>
      </table>
    </div>
      
    <!-- Strokes Section -->
    <template v-if="hide_sg_sc == 'N'">
      <h3 class="strokesGainedTitle" 
        v-if="playerCardData['sg_total_R' + buttonRound] !== '' ||
        playerCardData['sg_drive_R' + buttonRound] !== '' ||
        playerCardData['sg_approach_R' + buttonRound] !== '' ||
        playerCardData['sg_short_R' + buttonRound] !== '' ||
        playerCardData['sg_putting_R' + buttonRound] !== ''
      "
      >Strokes Gained</h3>
      <table id="scoreCard" 
        v-if="playerCardData['sg_total_R' + buttonRound] !== '' ||
        playerCardData['sg_drive_R' + buttonRound] !== '' ||
        playerCardData['sg_approach_R' + buttonRound] !== '' ||
        playerCardData['sg_short_R' + buttonRound] !== '' ||
        playerCardData['sg_putting_R' + buttonRound] !== ''
      ">
        <thead>
          <tr class="t3">
            <th class="titleWidth">
              Round
            </th>
            <th class="titleWidth">
              Driving
            </th>
            <th class="titleWidth">
              Approach
            </th>
            <th class="titleWidth">
              Short Game
            </th>
            <th class="titleWidth">
              Putting
            </th>
            <th class="titleWidth">
              Total
            </th>
          </tr>
        </thead>
        <tbody >
          <template>
            <template>
              <template>
                <tr class="RoundColour" v-for="(i, index) in range(1, roundsPlayed)" :key="index"
                  v-show="playerCardData['sg_drive_R' + i] !== ''">
                  <template>
                    <td >R{{ i }} <span v-if="data.multi_course == 'Y'" :class="playerCardData['course_colour_R' + i]">•</span></td>
                  </template>
                  <template>
                    <template>
                      <td>{{playerCardData['sg_drive_R' + i]}}</td>
                      <td>{{playerCardData['sg_approach_R' + i]}}</td>
                      <td>{{playerCardData['sg_short_R' + i]}}</td>
                      <td>{{playerCardData['sg_putting_R' + i]}}</td>
                      <td>{{playerCardData['sg_total_R' + i]}}</td>
                    </template>
                  </template>
                </tr>
                <tr>
                  <td>
                    <span v-if="sg_avg == 'Y'">Average</span>
                    <span v-else>Total</span>
                  </td>
                  <td>{{playerCardData.sg_drive}}</td>
                  <td>{{playerCardData.sg_approach}}</td>
                  <td>{{playerCardData.sg_short}}</td>
                  <td>{{playerCardData.sg_putting}}</td>
                  <td>{{playerCardData.sg_total}}</td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </template>

    <!-- Extra Data Sectiom -->
    <template v-if="data.stats_avail == 'Y'">
      <!-- Progressive Score Against Par -->
      <template v-if="playerCardData.stats.progressive">
        <h3 class="strokesGainedTitle" 
        >Progressive Score Against Par</h3>
        <table id="scoreCard" class="ExtraSection ProgressivePar parProgress">
          <thead>
            <tr class="t3">
              <th>
                Hole
              </th>
              <th>
                1
              </th>
              <th>
                2
              </th>
              <th>
                3
              </th>
              <th>
                4
              </th>
              <th>
                5
              </th>
              <th>
                6
              </th>
              <th>
                7
              </th>
              <th>
                8
              </th>
              <th>
                9
              </th>
              <th>
                10
              </th>
              <th>
                11
              </th>
              <th>
                12
              </th>
              <th>
                13
              </th>
              <th>
                14
              </th>
              <th>
                15
              </th>
              <th>
                16
              </th>
              <th>
                17
              </th>
              <th>
                18
              </th>
            </tr>
          </thead>

          <tbody >
            <template v-if="data.multi_course == 'Y'">
              <tr
                class="t3 parTD"
                :class="multi.course_X_colour + 'row'"
                v-for="(multi, index) in data.courses.courses_entry"
                :key="index"
              >
                <td>{{ multi.course_X_id }} Par</td>
                <td
                  v-for="(par, index) in multi.course_X_par.split(',').slice(0, 9)"
                  :key="index + Math.random()"
                >
                  {{ par }}
                </td>
                <td>
                  {{ multi.course_X_out_par.slice(1, 6) }}
                </td>
                <td
                  v-for="(par, index) in multi.course_X_par.split(',').slice(9, 18)"
                  :key="index + Math.random()"
                >
                  {{ par }}
                </td>
                <td>
                  {{ multi.course_X_in_par.slice(1, 6) }}
                </td>
                <td>
                  {{ multi.course_X_total_par }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr class="t3">
                <td>
                  Par
                </td>
                <td
                  v-for="(yard, index) in data.course_par.split(',').slice(0, 9)"
                  :key="index + Math.random()"
                >
                  {{ yard }}
                </td>
                <td
                  v-for="(number, index) in data.course_par.split(',').slice(9, 18)"
                  :key="index + Math.random()"
                >
                  {{ number }}
                </td>
              </tr>
            </template>
            <template>
              <template v-if="team == 'Y'">
                <tr class="RoundColour parTD">
                  <td>R{{ roundsPlayed }} <span v-if="data.multi_course == 'Y'" :class="playerCardData['course_colour_R' + roundsPlayed]">•</span></td>
                  <template v-if="tmparams.stableford == 'N'">
                    <td
                      v-for="(r1, index) in playerCardData['team_scores_R' + roundsPlayed].split(',').slice(0, 9)"
                      :key="index + Math.random()"
                    >
                      <span
                        class="twoUnder"
                        v-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == -2"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneUnder"
                        v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == -1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneOver"
                        v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == 1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="
                        "
                        v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == 2"
                      >
                        {{ r1 }}
                      </span>
                      <span v-else>
                        {{ r1 }}
                      </span>
                    </td>
                    <td>{{ outVal(playerCardData['team_scores_R' + roundsPlayed]) }}</td>
                    <td
                      v-for="(r1, index) in playerCardData['team_scores_R' + roundsPlayed].split(',').slice(9, 18)"
                      :key="index + Math.random()"
                    >
                      <span
                        class="twoUnder"
                        v-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -2"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneUnder"
                        v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneOver"
                        v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == 1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="twoOver"
                        v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == 2"
                      >
                        {{ r1 }}
                      </span>
                      <span v-else>
                        {{ r1 }}
                      </span>
                    </td>
                    <td>{{ inVal(playerCardData['team_scores_R' + roundsPlayed]) }}</td>
                    <td>{{ totalVal(playerCardData['team_scores_R' + roundsPlayed])}}</td>
                  </template>
                  <template v-else>
                    <td
                      v-for="(r1, index) in playerCardData['team_points_R' + roundsPlayed].split(',').slice(0, 9)"
                      :key="index + Math.random()"
                    >
                      <span
                        class="twoUnder"
                        v-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == -2"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneUnder"
                        v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == -1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneOver"
                        v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == 1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="twoOver"
                        v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',')[index] == 2"
                      >
                        {{ r1 }}
                      </span>
                      <span v-else>
                        {{ r1 }}
                      </span>
                    </td>
                    <td>{{ outVal(playerCardData['team_points_R' + roundsPlayed]) }}</td>
                    <td
                      v-for="(r1, index) in playerCardData['team_points_R' + roundsPlayed].split(',').slice(9, 18)"
                      :key="index + Math.random()"
                    >
                      <span
                        class="twoUnder"
                        v-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -2"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneUnder"
                        v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == -1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="oneOver"
                        v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == 1"
                      >
                        {{ r1 }}
                      </span>
                      <span
                        class="twoOver"
                        v-else-if="playerCardData['team_vspars_R' + roundsPlayed].split(',').slice(9, 18)[index] == 2"
                      >
                        {{ r1 }}
                      </span>
                      <span v-else>
                        {{ r1 }}
                      </span>
                    </td>
                    <td>{{ inVal(playerCardData['team_points_R' + roundsPlayed]) }}</td>
                    <td>{{ totalVal(playerCardData['team_points_R' + roundsPlayed])}}</td>
                  </template>
                </tr>
              </template>
              <template v-else>
                <template>
                  <tr class="RoundColour parTD" v-for="(i, index) in range(1, roundsPlayed)" :key="index">                    
                    <td>R{{ i }} <span v-if="data.multi_course == 'Y'" :class="playerCardData['course_colour_R' + i]">•</span></td>
                    <template v-if="tmparams.stableford == 'N'">
                      <template v-if="playerCardData['green_in_reg_R' + i]">
                        <td
                          v-for="(r1, index) in playerCardData.stats.progressive['score_R' + i].split(',').slice(0, 18)"
                          :key="index + Math.random()"
                        >
                          <span
                            v-if="r1 == 'Par'"
                          >
                            {{ r1 }} 
                          </span>
                          <span
                            class="oneUnder"
                            v-else-if="r1.includes('-')"
                          >
                            {{ r1 }} 
                          </span>
                          <span
                            class="oneOver"
                            v-else-if="r1.includes('+')"
                          >
                            {{ r1 }} 
                          </span>
                        </td>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="playerCardData['green_in_reg_R' + buttonRound]">
                        <td
                          v-for="(r1, index) in playerCardData['points_R' + buttonRound].split(',').slice(0, 21)"
                          :key="index + Math.random()"
                        >
                          <span
                            class="twoUnder"
                            v-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == -2"
                          >
                            {{ r1 }}
                          </span>
                          <span
                            class="oneUnder"
                            v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == -1"
                          >
                            {{ r1 }}
                          </span>
                          <span
                            class="oneOver"
                            v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == 1"
                          >
                            {{ r1 }}
                          </span>
                          <span
                            class="twoOver"
                            v-else-if="playerCardData['vspar_R' + buttonRound].split(',')[index] == 2"
                          >
                            {{ r1 }}
                          </span>
                          <span v-else>
                            {{ r1 }}
                          </span>
                        </td>
                        <!-- <td>{{ outVal(playerCardData['points_R' + buttonRound]) }}</td>
                        <td
                          v-for="(r1, index) in playerCardData['points_R' + buttonRound].split(',').slice(9, 18)"

                          :key="index + Math.random()"
                        >
                          <span
                            class="twoUnder"
                            v-if="playerCardData['vspar_R' + buttonRound].split(',').slice(9, 18)[index] == -2"
                          >
                            {{ r1 }}
                          </span>
                          <span
                            class="oneUnder"
                            v-else-if="playerCardData['vspar_R' + buttonRound].split(',').slice(9, 18)[index] == -1"
                          >
                            {{ r1 }}
                          </span>
                          <span
                            class="oneOver"
                            v-else-if="playerCardData['vspar_R' + buttonRound].split(',').slice(9, 18)[index] == 1"
                          >
                            {{ r1 }}
                          </span>
                          <span
                            class="twoOver"
                            v-else-if="playerCardData['vspar_R' + buttonRound].split(',').slice(9, 18)[index] == 2"
                          >
                            {{ r1 }}
                          </span>
                          <span v-else>
                            {{ r1 }}
                          </span>
                        </td>
                        <td>{{ inVal(playerCardData['points_R' + buttonRound]) }}</td>
                        <td>{{ totalVal(playerCardData['points_R' + buttonRound])}}</td> -->
                      </template>
                    </template>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </template>

      <!-- Summary and Analysis -->
      <template v-if="playerCardData.stats.sum_analysis">
        <h3 class="strokesGainedTitle" 
        >Summary and Analysis</h3>
        <table id="scoreCard" class="ExtraSection ProgressivePar">
          <thead>
            <tr class="t3">
              <th class="sumWidth">
                Round
              </th>
              <th class="sumWidth">
                Date
              </th>
              <th class="sumWidth">
                Start Time
              </th>
              <th class="sumWidth">
                Start Tee
              </th>
              <th class="sumWidth">
                Holes
              </th>
              <th class="sumWidth">
                Score
              </th>
              <th class="sumWidth">
                vsPar
              </th>
              <th class="sumWidth">
                Birdies
              </th>
              <th class="sumWidth">
                Pars
              </th>
              <th class="sumWidth">
                Bogeys
              </th>
            </tr>
          </thead>
          <tbody >
            <tr v-for="(sum, index) in playerCardData.stats.sum_analysis.round_entry" :key="index">
              <td>{{sum.round}}</td>
              <td>{{sum.date}}</td>
              <td>{{sum.start_time}}</td>
              <td>{{sum.start_tee}}</td>
              <td>{{sum.holes}}</td>
              <td>{{sum.score}}</td>
              <td>{{sum.par}}</td>
              <td>{{sum.birdies}}</td>
              <td>{{sum.pars}}</td>
              <td>{{sum.bogies}}</td>
            </tr>
          </tbody>
        </table>
      </template>

      <!-- 6 small stats -->
      <template>
        <b-row class="smallStats">
          <b-col cols="6" v-if="driving_dist !== undefined">
            <template>
              <h3 class="strokesGainedTitle" 
              >Driving Distance</h3>
              <table id="scoreCard" class="ExtraSection ProgressivePar">
                <thead>
                  <tr class="t3">
                    <th class="sumWidth">
                      Round
                    </th>
                    <th class="sumWidth">
                      Measured
                    </th>
                    <th class="sumWidth">
                      Distance
                    </th>
                    <th class="sumWidth">
                      Average
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(dd, index) in driving_dist.round_entry" :key="index">
                    <td>{{dd.round}}</td>
                    <td>{{dd.measured}}</td>
                    <td>{{dd.distance}}</td>
                    <td>{{dd.average}}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </b-col>
          <b-col cols="6" v-if="drive_acc !== undefined">
            <template>
              <h3 class="strokesGainedTitle" 
              >Driving Accuracy</h3>
              <table id="scoreCard" class="ExtraSection ProgressivePar">
                <thead>
                  <tr class="t3">
                    <th class="sumWidth">
                      Round
                    </th>
                    <th class="sumWidth">
                      Drives
                    </th>
                    <th class="sumWidth">
                      Fairways
                    </th>
                    <th class="sumWidth">
                      %
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(da, index) in drive_acc.round_entry" :key="index">
                    <td>{{da.round}}</td>
                    <td>{{da.drives}}</td>
                    <td>{{da.fairways}}</td>
                    <td>{{da.pcent}}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </b-col>
          <b-col cols="6" v-if="sand_saves !== undefined">
            <template>
              <h3 class="strokesGainedTitle" 
              >Sand Saves</h3>
              <table id="scoreCard" class="ExtraSection ProgressivePar">
                <thead>
                  <tr class="t3">
                    <th class="sumWidth">
                      Round
                    </th>
                    <th class="sumWidth">
                      Attempts
                    </th>
                    <th class="sumWidth">
                      Saves
                    </th>
                    <th class="sumWidth">
                      %
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(ss, index) in sand_saves.round_entry" :key="index">
                    <td>{{ss.round}}</td>
                    <td>{{ss.attempts}}</td>
                    <td>{{ss.saves}}</td>
                    <td>{{ss.pcent}}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </b-col>
          <b-col cols="6" v-if="gir !== undefined">
            <template>
              <h3 class="strokesGainedTitle" 
              >Greens in Regulation</h3>
              <table id="scoreCard" class="ExtraSection ProgressivePar">
                <thead>
                  <tr class="t3">
                    <th class="sumWidth">
                      Round
                    </th>
                    <th class="sumWidth">
                      Holes
                    </th>
                    <th class="sumWidth">
                      Greens
                    </th>
                    <th class="sumWidth">
                      %
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(da, index) in gir.round_entry" :key="index">
                    <td>{{da.round}}</td>
                    <td>{{da.holes}}</td>
                    <td>{{da.greens}}</td>
                    <td>{{da.pcent}}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </b-col>
          <b-col cols="6" v-if="putting_ave !== undefined">
            <template>
              <h3 class="strokesGainedTitle" 
              >Putting Average</h3>
              <table id="scoreCard" class="ExtraSection ProgressivePar">
                <thead>
                  <tr class="t3">
                    <th class="sumWidth">
                      Round
                    </th>
                    <th class="sumWidth">
                      Holes
                    </th>
                    <th class="sumWidth">
                      Putts
                    </th>
                    <th class="sumWidth">
                      Avg./Hole
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(dd, index) in putting_ave.round_entry" :key="index">
                    <td>{{dd.round}}</td>
                    <td>{{dd.holes}}</td>
                    <td>{{dd.putts}}</td>
                    <td>{{dd['avg-hole']}}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </b-col>
          <b-col cols="6" v-if="pgir !== undefined">
            <template>
              <h3 class="strokesGainedTitle" 
              >Putts per Green in Regulation</h3>
              <table id="scoreCard" class="ExtraSection ProgressivePar">
                <thead>
                  <tr class="t3">
                    <th class="sumWidth">
                      Round
                    </th>
                    <th class="sumWidth">
                      Holes
                    </th>
                    <th class="sumWidth">
                      putts
                    </th>
                    <th class="sumWidth">
                      Avg./Hole
                    </th>
                  </tr>
                </thead>
                <tbody >
                  <tr v-for="(da, index) in pgir.round_entry" :key="index">
                    <td>{{da.round}}</td>
                    <td>{{da.holes}}</td>
                    <td>{{da.putts}}</td>
                    <td>{{da['avg-hole']}}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "playerReportCard",
  props: [
    "data", 
    "playerCardData", 
    "roundsPlayed", 
    "team", 
    "courses", 
    "develop", 
    "tmparams", 
    "sg_avg",
    "hide_sg_sc", 
    "driving_dist",
    "drive_acc",
    "sand_saves",
    "gir",
    "putting_ave",
    "pgir"
  ],
  data() {
    return {
      oneUnder: "oneUnder",
      twoUnder: "twoUnder",
      threeUnder: "threeUnder",
      oneOver: "oneOver",
      twoOver: "twoOver",
      buttonRound: this.roundsPlayed,
      isActive: '',
    };
  },
  methods: {

    showData() {
      Array.from(this.playerCardData).forEach(element => 
        console.log(element)
      );
    },
    showRound(index) {
      console.log("index")
      console.log(index)
      this.buttonRound = this.roundsPlayed
      return this.buttonRound = index +1
    },

    StatoutVal(value, inOut) {
      // var split = value.split(",");
      // var front9 = split.slice(0, 9);
      var full = value.split(',')
      var fullCheck = full.filter((str) => str !== '' && str !== 'N');
      var fullTotal = full.filter((str) => str !== '')
      var front9Full = full.slice(0,9)
      var back9Full = full.slice(9,18)
      var front9Check = front9Full.filter((str) => str !== '' && str !== 'N');
      var frontTotal = front9Full.filter((str) => str !== '')
      var back9Check = back9Full.filter((str) => str !== '' && str !== 'N');
      var backTotal = back9Full.filter((str) => str !== '')
      if(inOut === 'front'){
        var total = front9Check.length + '/' + frontTotal.length
      }
      else if(inOut === 'back'){
        total = back9Check.length + '/' + backTotal.length
      }
      else{
        total = fullCheck.length + '/' + fullTotal.length
      }
      return total;
    },
    outVal(value) {
      var split = value.split(",");
      var front9 = split.slice(0, 9);
      for (var i = 0; i < front9.length; i++) front9[i] = +front9[i];
      var sum = front9.reduce(function(a, b) {
        return a + b;
      }, 0);
      if (sum == 0) {
        return sum = ''
      } else {
        return sum; 
      }
    },
    inVal(value) {
      var split = value.split(",");
      var back9 = split.slice(10, 19);
      for (var i = 0; i < back9.length; i++) back9[i] = +back9[i];
      var sum = back9.reduce(function(a, b) {
        return a + b;
      }, 0);
      if (sum == 0) {
        return sum = ''
      } else {
        return sum; 
      }
    },
    totalVal(value) {
      var split = value.split(",");
      for (var i = 0; i < split.length; i++) split[i] = +split[i];
      var sum = split.reduce(function(a, b) {
        return a + b;
      }, 0);
      if (sum == 0) {
        return sum = ''
      } else {
        return sum; 
      }
    },
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    getArraySum: function(array, f, t, zeroVal) 
    {
      var result = 0;

      if (Array.isArray(array))
      {
        if (!f) f = 0;
        if (!t) t = (array.length - 1);

        for (var i = f; i <= t; i++)
        {
          var arrVal = array[i];

          if (arrVal != "" && !isNaN(arrVal))
            result += parseInt(arrVal);
        }
      }

      if (result == 0 && typeof zeroVal == "string")
        result = zeroVal;

      return result;
    }
  },
  computed: {
    roundsPlayeds() {
      return parseInt(this.roundsPlayed);
    },
  },
  mounted() {
    this.showData()
    this.showRound(parseInt(this.roundsPlayed) - 1);
    this.getArraySum();
  }
};
</script>

<style scoped>
.smallStats {
  padding-left: 60px;
  padding-right: 60px;
}
.playerCard {
  height: 500px;
  overflow: scroll;
}
.sumWidth {
  width: 10%;
}
.titleWidth {
 width: 16.6666666667%;
}
.strokesGainedTitle {
  color: #133f7b;
  font-size: 1rem;
  margin-top: 30px;
}
::v-deep .t3:hover {
  background-color: #03477b !important;
  color: white;
}
::v-deep span.oneOver.oneOverrColor {
  color: #1e90ff;
  background: #fff;
}
::v-deep span.twoUnder.twoUnderColor {
  color: #a52a2a;
  background: transparent;
}
::v-deep span.oneUnder.oneUnderColor {
  background: transparent;
  color: #a52a2a;
}
::v-deep span.twoOver.twoOverColor {
  background: transparent;
  color: #1e90ff;
}
::v-deep .tabsConent {
  margin-left: 108.5px;
  margin-bottom: 0em;
}
.btn-group.btn-group-sm {
  margin-left: 10%;
  float: left;
}
.roundBtnColour {
  background-color: #42a7c6;
  border-color: #42a7c6;
  border-radius: 0px;
}
tr.RoundColour.nonRound:nth-child(odd) {
  background-color: #fff!important;
  color: #000!important;
}
tr.RoundColour.nonRound:nth-child(even) {
  background-color: rgba(0,0,0,.03)!important;
  color: #000!important;
}
.black {
  color: #000;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
div#desktop {
  margin-top: 1rem;
}
tr.RoundColour {
  /* background-color: #b7d8fb!important; */
}
#scoreCard {
  width: 80%;
  text-align: center;
  margin-left: 10%;
  font-size: 14px;
}
th {
  padding: 5px;
  font-weight: 400;
}
td {
  padding: 5px;
}
#mobile {
  display: none;
}
.oneOver {
  color: #fff;
  background: #1e90ff;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
}
.twoOver {
  color: #fff;
  background: #e07020;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
}
.oneUnder {
  color: #fff;
  background: #a52a2a;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
}
.twoUnder {
  color: #fff;
  background: #3c9933;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
}
.threeUnder {
  color: #fff;
  background: #fbbc04;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
}
.t3 {
  background-color: #03477b !important;
  color: white;
}
::v-deep th:first-child {
  /* border-top-left-radius: 15px; */
}
::v-deep th:last-child {
  /* border-top-right-radius: 15px; */
}
tr:nth-child(even) {
/* background-color: rgba(0,0,0,.03); */
}
tr:nth-child(odd) {
  background-color: white;
}
.Brow {
  background-color: #333333 !important;
}
.Rrow {
  background-color: #bb0000 !important;
}
.B {
  color: #333333 !important;
  font-size: 40px !important;
  line-height: 25px !important;
  vertical-align: bottom;
}
.R {
  color: #bb0000 !important;
  font-size: 40px !important;
  line-height: 25px !important;
  vertical-align: bottom;
}
.check {
  color: green;
}
.cross {
  color: darkred;
}
@media only screen and (max-width: 500px) {
  ::v-deep .nav-tabs  > li.nav-item + li.nav-item {
    display: block!important;
  }
  ::v-deep .nav-tabs  > li.nav-item {
    width: 100%!important;
  }
}
@media only screen and (max-width: 480px) {
  #scoreCard {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
  #desktop {
    display: none;
  }
  #mobile {
    display: block;
  }
  ::v-deep .mt-3 {
    margin-top: 0 !important;
  }
  ::v-deep .nav-tabs .nav-link.active {
    color: white;
    background-color: #263056;
    text-align: center;
    width: 100%;
    border-color: #263056;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  ::v-deep .nav-tabs .nav-link {
    text-align: center;
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  ::v-deep .nav-link {
    color: black;
    width: 50%;
  }
  ::v-deep .nav-item {
    width: 50%;
  }
  .blue {
    background-color: #133f7b !important;
    color: white;
  }
}
</style>