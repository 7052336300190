<template>
  <div class="playerprofile">
    <b-container>
      <div class="playerBlock">
        <b-row class="topSectionInfo">
          <template v-if="playerinfo.IMAGE == 'Y'">
            <b-col lg="3" md="3" cols="12" class="backPlayer"  v-bind:style="{ 'background-image': 'url(' + config.VUE_APP_PLAYER_IMAGE + 'media/photos/' + refno + '.jpg' + ')' }">
            </b-col>
          </template>
          <template v-else>
            <b-col lg="3" md="3" cols="12" class="backPlayer"  v-bind:style="{ 'background-image': 'url(' + 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/Amateur_2.png' + ')' }">
            </b-col>
          </template>
          <b-col lg="9" md="9" cols="12" class="InfoB">
            <div class="blockInfo">
              <b-row>
                <b-col>
                  <h3 class="ppFullName">
                    {{ playerinfo.MEMBER.FULL_NAME }}
                    <span v-if="show_name_pronounce">
                      <b-button @click="namePronounce()" class="namePronounce"
                        ><font-awesome-icon
                          class="icon"
                          :icon="['fa', 'volume-up']"
                      /></b-button>
                    </span>
                    <!-- <span
                      class="natPP"
                      v-if="
                        playerinfo.PRONOUNCIATIONS.PRONOUNCIATION.PHOTO_IMAGE
                          .length > 0
                      "
                    >
                      <img
                        class="flag"
                        :src="
                          (config.VUE_APP_FLAG_URL +
                            playerinfo.MEMBER.NATIONALITY)
                            | lowercase
                        "
                      />
                    </span> -->
                    <span class="natPP">
                      <img
                        class="flag"
                        :src="
                          (config.VUE_APP_FLAG_URL +
                            playerinfo.MEMBER.NATIONALITY)
                            | lowercase
                        "
                      />
                    </span>
                  </h3>
                </b-col>
              </b-row>
              <template v-if="isMobile(true)">
                <div
                  class="statInfo"
                  v-for="(info, index) in statics"
                  :key="index"
                >
                  <b-row v-if="info.CODE == 'B010'">
                    <b-col>
                      <h3 class="ppStat">{{ info.PLAYED }}</h3>
                      <span class="oomName"> Tournaments Played</span>
                    </b-col>
                    <b-col>
                      <h3 class="ppStat">{{ info.VALUE }}</h3>
                      <span class="oomName"> Wins</span>
                    </b-col>
                  </b-row>
                  <b-row v-if="info.CODE == 'C500'">
                    <b-col>
                      <h3 class="ppStat">{{ value }}</h3>
                      <span class="oomName"> Top Ten Finishes</span>
                    </b-col>
                    <b-col>
                      <h3 class="ppStat">{{ info.VALUE }}</h3>
                      <span class="oomName"> Lowest Round</span>
                    </b-col>
                  </b-row>
                </div>
              </template>
              <template v-else>
                <b-row>
                  <b-col>
                    <div
                      class="statInfo"
                      v-for="(info, index) in statics"
                      :key="index"
                    >
                      <h3 class="ppStat" v-if="info.CODE == 'B010'">
                        {{ info.PLAYED }}
                        <span class="oomName">Tournaments Played</span>
                      </h3>
                      <h3 class="ppStat" v-if="info.CODE == 'B010'">
                        {{ info.VALUE }}
                        <span class="oomName">Wins</span>
                      </h3>
                      <h3 class="ppStat" v-if="info.CODE == 'B020'">
                        {{ info.VALUE }}
                        <span class="oomName">Top Ten Finishes</span>
                      </h3>
                      <h3 class="ppStat" v-if="info.CODE == 'C500'">
                        {{ info.VALUE }}
                        <span class="oomName">Lowest Round</span>
                      </h3>
                    </div>
                  </b-col>
                </b-row>
              </template>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-tabs>
          <b-tab
            class="section"
            title="Player Info"
            content-class="PlayerBlock"
          >
            <template v-if="isMobile(true)">
              <b-row>
                <b-col class="playerDetails" v-if="playerinfo.MEMBER.AGE.length"
                  >Age:</b-col
                >
                <b-col class="playerDetails RightDetails">{{
                  playerinfo.MEMBER.AGE
                }}</b-col>
              </b-row>
              <b-row>
                <b-col
                  class="playerDetails"
                  v-if="playerinfo.MEMBER.NATIONALITY.length"
                  >Nationality:</b-col
                >
                <b-col class="playerDetails RightDetails">{{
                  playerinfo.MEMBER.NATIONALITY
                }}</b-col>
              </b-row>
              <b-row>
                <b-col
                  class="playerDetails"
                  v-if="playerinfo.MEMBER.DATE_TURNED_PRO.length"
                  >Turned Pro:</b-col
                >
                <b-col class="playerDetails RightDetails">{{
                  playerinfo.MEMBER.DATE_TURNED_PRO.slice(4)  
                }}</b-col>
              </b-row>
              <b-row>
                <b-col
                  class="playerDetails"
                  v-if="playerinfo.MEMBER.REPRESENTS.length"
                  >Represents:</b-col
                >
                <b-col class="playerDetails RightDetails">{{
                  playerinfo.MEMBER.REPRESENTS
                }}</b-col>
              </b-row>
              <b-row>
                <b-col
                  class="playerDetails"
                  v-if="playerinfo.MEMBER.PLACE_OF_BIRTH.length"
                  >Place of birth:</b-col
                >
                <b-col class="playerDetails RightDetails">{{
                  playerinfo.MEMBER.PLACE_OF_BIRTH
                }}</b-col>
              </b-row>
              <b-row>
                <b-col
                  class="playerDetails"
                  v-if="playerinfo.MEMBER.HEIGHT.length"
                  >Height:</b-col
                >
                <b-col class="playerDetails RightDetails">{{
                  playerinfo.MEMBER.HEIGHT
                }}</b-col>
              </b-row>
              <b-row>
                <b-col
                  class="playerDetails"
                  v-if="playerinfo.MEMBER.EXEMPT_STATUS.length"
                  >Exemption Status:</b-col
                >
                <b-col class="playerDetails RightDetails">{{
                  playerinfo.MEMBER.EXEMPT_STATUS
                }}</b-col>
              </b-row>
              <b-row>
                <b-col
                  class="playerDetails"
                  v-if="playerinfo.MEMBER.WEBSITE.length"
                  >Website:</b-col
                >
                <b-col class="playerDetails RightDetails">
                  <b-link
                    :href="playerinfo.MEMBER.WEBSITE"
                    target="_blank"
                    class="websiteLink"
                  >
                    {{ playerinfo.MEMBER.WEBSITE }}
                  </b-link>
                </b-col>
              </b-row>
            </template>
            <template v-else>
              <b-row class="playerMainStats">
                <b-col>
                  <b-row>
                    <b-col lg="4" md="4">
                      <div
                        v-if="playerinfo.MEMBER.AGE.length"
                        class="playerDetails"
                      >
                        Age:
                      </div>
                      <div
                        v-if="playerinfo.MEMBER.NATIONALITY.length"
                        class="playerDetails"
                      >
                        Nationality:
                      </div>
                      <div
                        v-if="playerinfo.MEMBER.DATE_TURNED_PRO.length"
                        class="playerDetails"
                      >
                        Turned Pro:
                      </div>
                      <div
                        v-if="playerinfo.MEMBER.REPRESENTS.length"
                        class="playerDetails"
                      >
                        Represents:
                      </div>
                    </b-col>
                    <b-col v-if="playerinfo.MEMBER.AGE.length" lg="8" md="8">
                      <div class="playerDetails">
                        {{ playerinfo.MEMBER.AGE }}
                      </div>
                      <div
                        v-if="playerinfo.MEMBER.NATIONALITY.length"
                        class="playerDetails"
                      >
                        {{ playerinfo.MEMBER.NATIONALITY }}
                      </div>
                      <div
                        v-if="playerinfo.MEMBER.DATE_TURNED_PRO.length"
                        class="playerDetails"
                      >
                        {{ playerinfo.MEMBER.DATE_TURNED_PRO.slice(4) }}
                      </div>
                      <div
                        v-if="playerinfo.MEMBER.REPRESENTS.length"
                        class="playerDetails"
                      >
                        {{ playerinfo.MEMBER.REPRESENTS }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <b-col
                      v-if="playerinfo.MEMBER.PLACE_OF_BIRTH.length"
                      lg="4"
                      md="4"
                    >
                      <div class="playerDetails">
                        Place of birth:
                      </div>
                      <div
                        v-if="playerinfo.MEMBER.HEIGHT.length"
                        class="playerDetails"
                      >
                        Height:
                      </div>
                      <div
                        v-if="playerinfo.MEMBER.EXEMPT_STATUS.length"
                        class="playerDetails"
                      >
                        Exemption Status:
                      </div>
                      <div
                        v-if="playerinfo.MEMBER.WEBSITE.length"
                        class="playerDetails"
                      >
                        Website:
                      </div>
                    </b-col>
                    <b-col lg="8" md="8">
                      <div
                        v-if="playerinfo.MEMBER.PLACE_OF_BIRTH.length"
                        class="playerDetails"
                      >
                        {{ playerinfo.MEMBER.PLACE_OF_BIRTH }}
                      </div>
                      <div
                        v-if="playerinfo.MEMBER.HEIGHT.length"
                        class="playerDetails"
                      >
                        <span v-if="playerinfo.MEMBER.HEIGHT.length > 0"
                          >{{ playerinfo.MEMBER.HEIGHT }}</span
                        >
                      </div>
                      <div
                        v-if="playerinfo.MEMBER.EXEMPT_STATUS.length"
                        class="playerDetails"
                      >
                        {{ playerinfo.MEMBER.EXEMPT_STATUS }}
                      </div>
                      <div
                        v-if="playerinfo.MEMBER.WEBSITE.length"
                        class="playerDetails"
                      >
                        <b-nav-item
                          :href="playerinfo.MEMBER.WEBSITE"
                          target="_blank"
                          class="websiteLink"
                        >
                          {{ playerinfo.MEMBER.WEBSITE }}
                        </b-nav-item>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>

            <b-row class="socialBlock">
              <b-col lg="4" md="4" v-if="playerinfo.MEMBER.TWITTER.length">
                <b-nav-item
                  class="twitterLogo"
                  :href="'https://www.twitter.com/' + playerinfo.MEMBER.TWITTER"
                  target="_blank"
                  ><span v-if="playerinfo.MEMBER.TWITTER.length"
                    ><font-awesome-icon
                      class="icon twit"
                      :icon="['fab', 'twitter']"/></span
                  ><span
                    v-if="playerinfo.MEMBER.TWITTER.length"
                    class="socialLink"
                    >Twitter:
                    <span class="nameLink">{{
                      playerinfo.MEMBER.TWITTER
                    }}</span></span
                  ></b-nav-item
                >
              </b-col>
              <b-col lg="4" md="4" v-if="playerinfo.MEMBER.FACEBOOK.length"
                ><b-nav-item
                  :href="
                    'https://www.facebook.com/' + playerinfo.MEMBER.FACEBOOK
                  "
                  target="_blank"
                  class="leftAlignLogo"
                  ><span v-if="playerinfo.MEMBER.FACEBOOK.length"
                    ><font-awesome-icon
                      class="icon fb"
                      :icon="['fab', 'facebook']"/></span
                  ><span
                    v-if="playerinfo.MEMBER.FACEBOOK.length"
                    class="socialLink"
                    >Facebook:
                    <span class="nameLink">{{
                      playerinfo.MEMBER.FACEBOOK
                    }}</span></span
                  ></b-nav-item
                >
              </b-col>
              <b-col lg="4" md="4" v-if="playerinfo.MEMBER.INSTAGRAM.length"
                ><b-nav-item
                  class="leftAlignLogo"
                  :href="
                    'https://www.instagram.com/' + playerinfo.MEMBER.INSTAGRAM
                  "
                  target="_blank"
                  ><span v-if="playerinfo.MEMBER.INSTAGRAM.length"
                    ><font-awesome-icon
                      class="icon insta"
                      :icon="['fab', 'instagram']"/></span
                  ><span
                    v-if="playerinfo.MEMBER.INSTAGRAM.length"
                    class="socialLink"
                    >Instagram:
                    <span class="nameLink">{{
                      playerinfo.MEMBER.INSTAGRAM
                    }}</span></span
                  ></b-nav-item
                >
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            v-if="playerinfo.BIOGRAPHY.length"
            class="section bioSection"
            title="Bio"
          >
            <span v-html="playerinfo.BIOGRAPHY" class="Bio"></span>
          </b-tab>
          <b-tab
            class="section"
            title="Whats In The Bag"
            content-class="PlayerBlock"
            v-if="ShowTab == 'Y'"
          >
            <b-row class="bagPad">
              <template >
                <b-col
                  class="MybagBlock"
                  lg="4"
                  v-for="(items, index) in myBagEquipment"
                  :key="index"
                >
                  <h5 class="brandItem-name">
                    <span v-if="items.TYPE == 'Ball'">Ball : </span>
                    <span v-if="items.TYPE !== 'Ball'">
                      <span v-if="items.TEMPLATE == '2'">{{
                        items.FIELD_1
                      }} </span>
                      <span v-if="items.EQUIP_DESC !== ''"
                        >{{ items.EQUIP_DESC }} : </span
                      >
                      <span v-if="items.FIELD_1 == '' && items.EQUIP_DESC == ''"
                        >{{ items.TYPE }} : </span
                      >
                      <!-- <span v-if="items.EQUIP_DESC !== ''">Equipment :</span> -->
                    </span>
                    <span class="Club-manufacturer">{{
                      items.MANUFACTURER
                    }}</span>
                  </h5>
                  <!-- <span class="ranking-playerProfile"  >
                    Type: {{ items.TYPE }}
                  </span> -->
                </b-col>
              </template>
              <!-- <template v-else>
                <b-col lg="4" class="MybagBlock">
                  <h5 class="brandItem-name">
                    <span v-if="playerinfo.MYBAG.EQUIPMENT_ITEM.TYPE == 'Ball'"
                      >Ball :
                    </span>
                    <span
                      v-if="playerinfo.MYBAG.EQUIPMENT_ITEM.TYPE !== 'Ball'"
                    >
                      <span
                        v-if="playerinfo.MYBAG.EQUIPMENT_ITEM.TEMPLATE == '2'"
                        >{{ playerinfo.MYBAG.EQUIPMENT_ITEM.FIELD_1 }}</span
                      >
                      <span
                        v-if="playerinfo.MYBAG.EQUIPMENT_ITEM.EQUIP_DESC !== ''"
                        >{{
                          playerinfo.MYBAG.EQUIPMENT_ITEM.EQUIP_DESC
                        }}
                        :</span
                      >
                    </span>
                    <span class="Club-manufacturer">{{
                      playerinfo.MYBAG.EQUIPMENT_ITEM.MANUFACTURER
                    }}</span>
                  </h5>
                  <span class="ranking-playerProfile">
                    Type: {{ playerinfo.MYBAG.EQUIPMENT_ITEM.TYPE }}
                  </span>
                </b-col>
              </template> -->
            </b-row>
          </b-tab>
        </b-tabs>
      </div>
    </b-container>

    <!-- Player Stats -->
    <div class="statTag">
      <b-container>
        <b-tabs>
          <b-tab
            class="section"
            title="Player Stats"
            :title-item-class="'playerstatsTitle '"
          >
            <div class="RankTitle">Season Rankings</div>
            <template v-if="isMobile(true)">
              <b-row class="SeasonRankingsTop_three">
                <b-col
                  lg="4"
                  v-for="(stat, index) in oomdataTourn"
                  :key="index"
                  v-show="stat.oom_code == 'PT'"
                >
                  <b-row>
                    <b-col>
                      <h5 class="Player-stats ng-binding mainThreeHeadings">
                        {{ stat.oom_name }}
                      </h5>
                    </b-col>
                    <b-col>
                      <h3 class="playerprofile-largeStat ng-binding">
                        {{ stat.pos }}
                        <span class="cupPlayerProfile">
                          <img
                            class="imageCup_playerProfile"
                            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/07/cup.webp"
                          />
                        </span>
                      </h3>
                    </b-col>
                  </b-row>
                  <hr />
                </b-col>
                <b-col
                  lg="4"
                  v-for="(stat, index) in oomdataTourn"
                  :key="index"
                  v-show="stat.oom_code == 'PT'"
                >
                  <b-row>
                    <b-col>
                      <h5
                        class="Player-stats mainThreeHeadings"
                        v-show="stat.oom_code == 'PT'"
                      >
                        Points
                      </h5>
                      <h5
                        class="Player-stats mainThreeHeadings"
                        v-show="stat.oom_code != 'PT'"
                      >
                        Winnings
                      </h5>
                    </b-col>
                    <b-col>
                      <h3 class="playerprofile-largeStat ng-binding">
                        {{ stat.points }}
                      </h3>
                    </b-col>
                  </b-row>
                  <hr />
                </b-col>
                <b-col
                  lg="4"
                  v-for="(stat, index) in oomdataTourn"
                  :key="index"
                  v-show="stat.oom_code == 'WR'"
                >
                  <b-row>
                    <b-col>
                      <h5 class="Player-stats ng-binding mainThreeHeadings">
                        {{ stat.oom_name }}
                      </h5>
                    </b-col>
                    <b-col>
                      <h3 class="playerprofile-largeStat ng-binding">
                        {{ wgr }}
                        <span class="cupPlayerProfile">
                          <img
                            class="imageCup_playerProfile"
                            src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/07/cup.webp"
                          />
                        </span>
                      </h3>
                    </b-col>
                  </b-row>
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  lg="4"
                  v-for="(stat, index) in playerStats"
                  :key="index"
                  class="SeasonPlayerStats"
                >
                  <b-row>
                    <b-col>
                      <h5 class="Player-stats">{{ stat.stats_name }}</h5>
                      <span class="ranking-playerProfile">
                        <span
                          v-show="stat.value > 0"
                          v-if="stat.value_type == 'A'"
                          >Average</span
                        >
                        <span
                          v-show="stat.value > 0"
                          v-if="stat.value_type == 'P'"
                          >Percentage</span
                        >
                        <span
                          v-show="stat.value > 0"
                          v-if="stat.value_type == 'T'"
                          >Total</span
                        >
                      </span>
                    </b-col>
                    <b-col>
                      <h4 class="Player-stats-value">{{ stat.value }}</h4>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>
            <template v-else>
              <b-row class="SeasonRankingsTop_three">
                <b-col
                  lg="4"
                  v-for="(stat, index) in oomData"
                  :key="index"
                  v-show="stat.oom_code == 'PT'"
                >
                  <h3 class="playerprofile-largeStat ng-binding">
                    {{ stat.pos }}
                    <span class="cupPlayerProfile">
                      <img
                        class="imageCup_playerProfile"
                        src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/07/cup.webp"
                      />
                    </span>
                  </h3>
                  <h5 class="Player-stats ng-binding">{{ stat.oom_name }}</h5>
                  <hr />
                </b-col>
                <b-col
                  lg="4"
                  v-for="(stat, index) in oomData"
                  :key="index"
                  v-show="stat.oom_code == 'PT'"
                >
                  <h3 class="playerprofile-largeStat ng-binding">
                    {{ stat.points }}
                  </h3>
                  <h5 class="Player-stats" v-show="stat.oom_code == 'PT'">
                    Points
                  </h5>
                  <h5 class="Player-stats" v-show="stat.oom_code != 'PT'">
                    Winnings
                  </h5>
                  <hr />
                </b-col>
                <b-col
                  lg="4"
                  v-for="(stat, index) in oomData"
                  :key="index"
                  v-show="stat.oom_code == 'WR'"
                >
                  <h3 class="playerprofile-largeStat ng-binding">
                    {{ wgr }}
                    <span class="cupPlayerProfile">
                      <img
                        class="imageCup_playerProfile"
                        src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/07/cup.webp"
                      />
                    </span>
                  </h3>
                  <h5 class="Player-stats ng-binding">{{ stat.oom_name }}</h5>
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  lg="4"
                  v-for="(stat, index) in playerStats"
                  :key="index"
                  class="SeasonPlayerStats"
                >
                  <b-row>
                    <b-col lg="10">
                      <h5 class="Player-stats">{{ stat.stats_name }}</h5>
                      <span class="ranking-playerProfile">
                        <span
                          v-show="stat.value > 0"
                          v-if="stat.value_type == 'A'"
                          >Average</span
                        >
                        <span
                          v-show="stat.value > 0"
                          v-if="stat.value_type == 'P'"
                          >Percentage</span
                        >
                        <span
                          v-show="stat.value > 0"
                          v-if="stat.value_type == 'T'"
                          >Total</span
                        >
                      </span>
                    </b-col>
                    <b-col lg="2">
                      <h4 class="Player-stats-value">{{ stat.value }}</h4>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>

            <hr class="lineBreak" />

            <div class="RankTitle">Career Rankings</div>

            <template v-if="isMobile(true)">
              <b-row class="CareerRankings_Block">
                <b-col
                  lg="4"
                  v-for="(stat, index) in statics"
                  :key="index"
                  class="SeasonPlayerStats"
                  v-show="stat.TOUR == 'LET'"
                >
                  <b-row>
                    <b-col>
                      <h5 class="Player-stats">{{ stat.DESCRIPTION }}</h5>
                      <span class="ranking-playerProfile">
                        <span
                          v-show="stat.VALUE > 0"
                          v-if="stat.STATS_TYPE == 'A'"
                          >Average</span
                        >
                        <span
                          v-show="stat.VALUE > 0"
                          v-if="stat.STATS_TYPE == 'P'"
                          >Percentage</span
                        >
                        <span
                          v-show="stat.VALUE > 0"
                          v-if="stat.STATS_TYPE == 'T'"
                          >Total</span
                        >
                      </span>
                    </b-col>
                    <b-col>
                      <h4 class="Player-stats-value">{{ stat.VALUE }}</h4>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>
            <template v-else>
              <b-row class="CareerRankings_Block">
                <b-col
                  lg="4"
                  v-for="(stat, index) in statics"
                  :key="index"
                  class="SeasonPlayerStats"
                  v-show="stat.TOUR == 'LET'"
                >
                  <b-row>
                    <b-col lg="10">
                      <h5 class="Player-stats">{{ stat.DESCRIPTION }}</h5>
                      <span class="ranking-playerProfile">
                        <span
                          v-show="stat.VALUE > 0"
                          v-if="stat.STATS_TYPE == 'A'"
                          >Average</span
                        >
                        <span
                          v-show="stat.VALUE > 0"
                          v-if="stat.STATS_TYPE == 'P'"
                          >Percentage</span
                        >
                        <span
                          v-show="stat.VALUE > 0"
                          v-if="stat.STATS_TYPE == 'T'"
                          >Total</span
                        >
                      </span>
                    </b-col>
                    <b-col lg="2">
                      <h4 class="Player-stats-value">{{ stat.VALUE }}</h4>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>

            <b-row>
              <b-col
                lg="4"
                md="4"
                cols="12"
                v-for="(stat, index) in filterLET"
                :key="index"
              >
                <b-row v-if="stat.DESCRIPTION.includes('(LET)')">
                  <b-col lg="10">
                    <h5 class="playStat">
                      {{ stat.DESCRIPTION }}
                    </h5>
                    <span
                      v-if="stat.STATS_TYPE === 'T' && stat.VALUE > 0"
                      class="rankingPP"
                      >Total</span
                    >
                    <span
                      v-if="stat.STATS_TYPE === 'A' && stat.VALUE > 0"
                      class="rankingPP"
                      >Average</span
                    >
                    <span
                      v-if="stat.STATS_TYPE === 'P' && stat.VALUE > 0"
                      class="rankingPP"
                      >Percentage</span
                    >
                  </b-col>
                  <b-col lg="2">
                    <h4 class="statPPVal">
                      {{ stat.VALUE }}
                    </h4>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-container>
    </div>
    <!-- Let Result and Letas Result -->
    <div class="thisSeaRec">
      <b-container>
        <b-tabs class="tableList">
          <b-tab title="LET" active :title-item-class="'titleBlut-Tab'">
            <div class="blockList">
              <b-row>
                <b-col lg="10" md="10">
                  <h3 class="rTag">
                    LET Results
                  </h3>
                </b-col>
                <b-col>
                  <div>
                    <b-form-select
                      v-model="year"
                      class="schedule-dropdown"
                      v-on:change="changeYear(year)"
                      v-if="seasonArray == 'Y'"
                    >
                      <b-form-select-option
                        class="opt-arrow"
                        v-for="(value,
                        index) in SeasonDrop.slice().reverse()"
                        :key="index"
                        :value="value.CODE"
                        >{{ value.NAME }}</b-form-select-option
                      >
                    </b-form-select>
                    <b-form-select
                      v-model="playerinfo.TIC_SEASONS.SEASON.CODE"
                      class="schedule-dropdown"
                      v-on:change="changeYear(playerinfo.TIC_SEASONS.SEASON.CODE)"
                      v-else
                    >
                      <b-form-select-option
                        class="opt-arrow"
                        :value="playerinfo.TIC_SEASONS.SEASON.CODE"
                        >{{ playerinfo.TIC_SEASONS.SEASON.NAME }}</b-form-select-option
                      >
                    </b-form-select>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>
                      Tournaments
                    </th>
                    <th class="Mobdate">
                      Date
                    </th>
                    <th>
                      Position
                    </th>
                    <th class="hideMob">
                      Rounds
                    </th>
                    <th class="hideMob">
                      Total
                    </th>
                    <th class="hideMob">
                      Prize Money
                    </th>
                    <!-- <th>
                      Points
                    </th> -->
                  </tr>
                </thead>
                <tbody >
                  <tr
                    v-for="(tourStat, index) in oomTournsData"
                    :key="index"
                    class="trLET"
                  >
                    <td class="tournName">
                      <b-nav-item
                        class="statsTournLET"
                        :to="
                          '/tournaments-information/' +
                            tourStat.tourn_code +
                            '/' +
                            id
                        "
                      >
                        {{ tourStat.tourn_name }}
                      </b-nav-item>
                    </td>
                    <td>
                      {{ tourStat.tourn_start }}
                    </td>
                    <td class="mobCenter">
                      <span v-if="tourStat.result_found === 'Y'">
                        {{ tourStat.final_pos }}
                      </span>
                    </td>
                    <td class="hideMob">
                      <span v-if="tourStat.result_found === 'Y'">
                        <span v-if="tourStat.r1_score.length">
                          {{ tourStat.r1_score }}
                        </span>
                        <span v-if="tourStat.r2_score.length">
                          {{ tourStat.r2_score }}
                        </span>
                        <span v-if="tourStat.r3_score.length">
                          {{ tourStat.r3_score }}
                        </span>
                        <span v-if="tourStat.r4_score.length">
                          {{ tourStat.r4_score }}
                        </span>
                      </span>
                    </td>
                    <td class="hideMob">
                      <span v-if="tourStat.result_found === 'Y'">
                        {{ tourStat.t_score }} ({{ tourStat.t_vspar }})
                      </span>
                    </td>
                    <td class="hideMob">
                      <span v-if="tourStat.result_found === 'Y'">
                        {{ tourStat.winnings }}
                      </span>
                    </td>
                    <!-- <template
                      v-if="
                        tourStat.tourn_ooms == '' ||
                          tourStat.tourn_ooms == undefined
                      "
                    >
                      <td></td>
                    </template>
                    <template v-else>
                      <td class="mobCenter">
                        <span
                          v-if="
                            tourStat.tourn_ooms.tourn_oom[0].t_oom_code == 'PT'
                          "
                        >
                          {{ tourStat.tourn_ooms.tourn_oom[0].t_oom_value }}
                        </span>
                        <span
                          v-else-if="
                            tourStat.tourn_ooms.tourn_oom[1].t_oom_code == 'PT'
                          "
                        >
                          {{ tourStat.tourn_ooms.tourn_oom[1].t_oom_value }}
                        </span>
                        <span
                          v-else-if="
                            tourStat.tourn_ooms.tourn_oom[2].t_oom_code == 'PT'
                          "
                        >
                          {{ tourStat.tourn_ooms.tourn_oom[2].t_oom_value }}
                        </span>
                        <span
                          v-else-if="
                            tourStat.tourn_ooms.tourn_oom.t_oom_code == 'PT'
                          "
                        >
                          {{ tourStat.tourn_ooms.tourn_oom.t_oom_value }}
                        </span>
                        <span
                          v-else-if="
                            tourStat.tourn_ooms.tourn_oom.t_oom_code == 'ET'
                          "
                        >
                          {{ tourStat.tourn_ooms.tourn_oom.t_oom_value }}
                        </span>
                        <span
                          v-else-if="
                            tourStat.tourn_ooms.tourn_oom.t_oom_code == 'S1'
                          "
                        >
                          {{ tourStat.tourn_ooms.tourn_oom.t_oom_value }}
                        </span>
                      </td>
                    </template> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
          <b-tab title="LETAS">
            <div class="blockList blockLetas">
              <b-row>
                <b-col lg="10">
                  <h3 class="rTag">
                    LETAS Results
                  </h3>
                </b-col>
                <b-col>
                  <div>
                    <b-form-select
                      v-model="year"
                      class="schedule-dropdown"
                      v-on:change="changeYear(year)"
                    >
                      <b-form-select-option
                        class="opt-arrow"
                        v-for="(value,
                        index) in SeasonDrop.slice().reverse()"
                        :key="index"
                        :value="value.CODE"
                        v-show="value.CODE > '2018'"
                        >{{ value.NAME }}</b-form-select-option
                      >
                    </b-form-select>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table-responsive">
              <table>
                <thead>
                  <tr class="thLetas">
                    <th>
                      Tournaments
                    </th>
                    <th>
                      Date
                    </th>
                    <th>
                      Position
                    </th>
                    <th>
                      Rounds
                    </th>
                    <th>
                      Total
                    </th>
                    <th>
                      Prize Money
                    </th>
                    <!-- <th>
                      Points
                    </th> -->
                  </tr>
                </thead>
                <tbody class="trLetas" v-if="oomTournsDataLETAS">
                  <tr
                    v-for="(tourStat, index) in oomTournsDataLETAS"
                    :key="index"
                  >
                    <td class="tournNameLetas">
                      {{ tourStat.tourn_name }}
                    </td>
                    <td>
                      {{ tourStat.tourn_start }}
                    </td>
                    <td>
                      <span v-if="tourStat.result_found === 'Y'">
                        {{ tourStat.final_pos }}
                      </span>
                    </td>
                    <td>
                      <span v-if="tourStat.result_found === 'Y'">
                        <span v-if="tourStat.r1_score.length">
                          {{ tourStat.r1_score }}
                        </span>
                        <span v-if="tourStat.r2_score.length">
                          {{ tourStat.r2_score }}
                        </span>
                        <span v-if="tourStat.r3_score.length">
                          {{ tourStat.r3_score }}
                        </span>
                        <span v-if="tourStat.r4_score.length">
                          {{ tourStat.r4_score }}
                        </span>
                      </span>
                    </td>
                    <td>
                      <span v-if="tourStat.result_found === 'Y'">
                        {{ tourStat.t_score }} ({{ tourStat.t_vspar }})
                      </span>
                    </td>
                    <td>
                      <span v-if="tourStat.result_found === 'Y'">
                        {{ tourStat.winnings }}
                      </span>
                    </td>
                    <td>
                      <span> </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
        </b-tabs>
      </b-container>
    </div>
    <!-- Flicker Section -->
    <div class="statTag pic">
      <b-container>
        <b-tabs>
          <b-tab
            class="section"
            @click="gotoflickre()"
            title="See More Photos"
            :title-item-class="'titleBlut-Tab SeeMore'"
          >
            <b-row>
              <b-col>
                <Flickr
                  :first_name="playerinfo.MEMBER.FORENAME_1"
                  :surname="playerinfo.MEMBER.SURNAME"
                  :refno="playerinfo.MEMBER.REFNO"
                  :config="config"
                />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-container>
    </div>
    <!-- Post Section -->
    <div class="thispostArea">
      <b-container>
        <b-tabs class="tableList">
          <b-tab
            title="News Articles"
            active
            :title-item-class="'titleBlut-Tab SeeMore'"
          >
            <div v-if="wptag.length">
              <Post :tag="wptag[0].id" />
            </div>
          </b-tab>
        </b-tabs>
      </b-container>
    </div>
  </div>
</template>

<script>
import Post from "@/components/posts.vue";
import Flickr from "@/components/flickr.vue";
import axios from "axios";

export default {
  name: "playerprofile",
  components: {
    Flickr,
    Post
  },
  props: ["refno", "season", "config"],
  data() {
    return {
      playerinfo: [],
      enhancedPlayerInfo: [],
      enhancedPlayerInfoLAS: [],
      id: this.season,
      year: this.season,
      prevyear: this.season -1,
      wptag: "",
      myBag: [],
      oomdataTourn: [],
      oomTournsData: [],
      playerStats: [],
      statics: [],
      oomData: [],
      showData: false,
      show_name_pronounce: false,
      show_my_bag: false,
      name_pronounce: "",
      myBagEquioment: [],
      ShowTab: '',
      SeasonDrop: [],
      seasonDropCode: '',
      seasonArray:''
    };
  },
  computed: {
    filterTTF: function() {
      return this.playerinfo.STATISTICS.STATISTIC.filter(
        playerinfo => !playerinfo.CODE.indexOf("B020")
      );
    },
    filterBSPM: function() {
      return this.playerinfo.STATISTICS.STATISTIC.filter(
        playerinfo => !playerinfo.CODE.indexOf("A015")
      );
    },
    filterCM: function() {
      return this.playerinfo.STATISTICS.STATISTIC.filter(
        playerinfo => !playerinfo.CODE.indexOf("A010")
      );
    },
    filterRTCDS: function() {
      if (Array.isArray(this.playerinfo.ORDERS_OF_MERIT.OOM))
        return this.playerinfo.ORDERS_OF_MERIT.OOM.filter(
          playerinfo => !playerinfo.CODE.indexOf("S020")
        );
      else return "NoRace";
    },
    filterTW: function() {
      return this.playerinfo.STATISTICS.STATISTIC.filter(
        playerinfo => !playerinfo.CODE.indexOf("B010")
      );
    },
    filterLRS: function() {
      return this.playerinfo.STATISTICS.STATISTIC.filter(
        playerinfo => !playerinfo.CODE.indexOf("C500")
      );
    }
    // filterLET: function() {
    //     if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
    //         console.log('isArray')
    //         return this.playerinfo.STATISTICS.STATISTIC.filter((letA) =>
    //           letA.DESCRIPTION.includes("(LET)")
    //         );
    //     } else {
    //         console.log('is not Array')
    //         return this.playerinfo.STATISTICS.STATISTIC.filter((letA) =>
    //           letA.DESCRIPTION.includes("(LET)")
    //         );
    //     }
    // },
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    }
  },
  methods: {
    namePronounce() {
      console.log("play name sound");
      var audio = new Audio(this.name_pronounce);
      audio.play();
    },
    gotoflickre() {
      // window.location.href="https://www.flickr.com/photos/ladieseuropeantour/";
      window.open(
        "https://www.flickr.com/photos/ladieseuropeantour/",
        "_blank"
      );
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    changeYear: function(year) {
      // var id = event.target.getAttribute("title");
      return (
        // (this.id = id),
        axios
          .get(
            process.env.VUE_APP_TMX_PLAYER +
              year +
              "/" +
              year +
              "-" +
              "players-" +
              this.refno +
              "?randomadd=" +
              new Date().getTime()
          )
          .then(response => {
            this.enhancedPlayerInfo = response.data;
            // this.oomTournsData = response.data.tourns.tourn_details;
            if (Array.isArray(response.data.tourns.tourn_details)) {
              console.log("isArray");
              this.oomTournsData = response.data.tourns.tourn_details;
            } else {
              console.log("is not Array");
              this.oomTournsData = [response.data.tourns.tourn_details];
            }
            this.playerStats = response.data.stats.tourn_details;
            return axios.get(
              process.env.VUE_APP_TMX_PLAYER +
                year +
                "/" +
                year +
                "-" +
                "players-" +
                this.refno +
                "?randomadd=" +
                new Date().getTime()
            );
          })
          .then(response => {
            this.enhancedPlayerInfoLAS = response.data;
            if (Array.isArray(response.data.tourns.tourn_details)) {
              console.log("isArray");
              this.oomTournsDataLETAS = response.data.tourns.tourn_details;
            } else {
              console.log("is not Array");
              this.oomTournsDataLETAS = [response.data.tourns.tourn_details];
            }
          })
      );
    },
    getprevyear: function() {
      axios
            return axios.get(
              process.env.VUE_APP_TMX_PLAYER +
                this.prevyear +
                "/" +
                this.prevyear +
                "-" +
                "players-" +
                this.refno +
                "?randomadd=" +
                new Date().getTime()
            )
        .then(response => {
            this.enhancedPlayerInfo = response.data;
            // this.oomTournsData = response.data.tourns.tourn_details;
            if (Array.isArray(response.data.tourns.tourn_details)) {
              console.log("isArray");
              this.oomTournsData = response.data.tourns.tourn_details;
            } else {
              console.log("is not Array");
              this.oomTournsData = [response.data.tourns.tourn_details];
            }
        });
    },
    getPosts: function() {
      axios
        .get(process.env.VUE_APP_WPAPI_URL + "wp/v2/tags?slug=" + this.refno)
        .then(response => {
          this.wptag = response.data;
        });
    }
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TMTX_BASE +
          this.refno +
          "?randomadd=" +
          new Date().getTime()
      )
      .then(response => {
        this.playerinfo = response.data;
        if (Array.isArray(response.data.TIC_SEASONS.SEASON)) {
          console.log("isArray");
          this.SeasonDrop = response.data.TIC_SEASONS.SEASON;
          this.seasonDropCode = response.data.TIC_SEASONS.SEASON[0].CODE
          this.seasonArray = 'Y'
        } else {
          console.log("is not Array");
          this.SeasonDrop = [response.data.TIC_SEASONS.SEASON];
          this.seasonDropCode = response.data.TIC_SEASONS.SEASON.CODE
          this.seasonArray = 'N'
        }
        // this.myBagEquioment = response.data.MYBAG.EQUIPMENT_ITEM;
        // if (Array.isArray(response.data.MYBAG.EQUIPMENT_ITEM)) {
        //   console.log("isArray");
        //   this.myBagEquipment = response.data.MYBAG.EQUIPMENT_ITEM;
        // } else {
        //   console.log("is not Array");
        //   this.myBagEquipment = [response.data.MYBAG.EQUIPMENT_ITEM];
        // }


        if (response.data.MYBAG.EQUIPMENT_ITEM) {
          console.log("mybag start");
          let myBag =
            response.data.MYBAG.EQUIPMENT_ITEM;
          if (!Array.isArray(myBag)) {
            myBag = [myBag];
          }
          if (myBag.length > 0) {
            this.show_my_bag = true;
            this.myBagEquioment = response.data.MYBAG.EQUIPMENT_ITEM;
            if (Array.isArray(response.data.MYBAG.EQUIPMENT_ITEM)) {
              console.log("isArray");
              this.myBagEquipment = response.data.MYBAG.EQUIPMENT_ITEM;
            } else {
              console.log("is not Array");
              this.myBagEquipment = [response.data.MYBAG.EQUIPMENT_ITEM];
            }
            console.log('YES')
            for (let i = 0; i < this.myBagEquioment.length; i++) {
                console.log(this.myBagEquioment[i].IN_BAG);
                this.ShowTab = this.myBagEquioment[i].IN_BAG
                console.log("this.ShowTab")
                console.log(this.ShowTab)
            }

            
          } else {
            console.log('NO')
          }
        }



        this.wgr = response.data.MEMBER.OWGR_RANKING;
        let statsNew = response.data.STATISTICS;
        this.statics = response.data.STATISTICS.STATISTIC;
        if (statsNew.length > 0) {
          if (this.statics[2].CODE == "B020") {
            this.value = this.statics[2].VALUE;
          } 
        }
        if (Array.isArray(this.statics)) {
          console.log("isArray");
          this.filterLet = response.data.STATISTICS.STATISTIC;
        } else {
          console.log("is not Array");
          this.filterLet = [response.data.STATISTICS.STATISTIC];
        }
        if (response.data.MEMBER.PRONOUNCIATIONS.PRONOUNCIATION) {
          console.log("player_pronounce start");
          let player_pronounce =
            response.data.MEMBER.PRONOUNCIATIONS.PRONOUNCIATION;
          if (!Array.isArray(player_pronounce)) {
            player_pronounce = [player_pronounce];
          }
          if (player_pronounce[0].PHOTO_IMAGE.length > 0) {
            this.show_name_pronounce = true;
            this.name_pronounce =
              this.config.VUE_APP_PLAYER_IMAGE + "members/" +
              this.refno +
              "/" +
              player_pronounce[0].PHOTO_IMAGE;
          }
        }

        return axios.get(
          process.env.VUE_APP_TMX_PLAYER +
            this.season +
            "/" +
            this.season +
            "-" +
            "players-" +
            this.refno +
            "?randomadd=" +
            new Date().getTime()
        );
      })
      .then(response => {
        if (!Object.keys(response.data).length) {
          console.log("True");
        } else {
          this.getPosts();
          this.enhancedPlayerInfo = response.data;
          console.log("this.enhancedPlayerInfo");
          console.log(this.enhancedPlayerInfo);
          if (this.enhancedPlayerInfo.tourns.data_format == "") {
            console.log("tourns no data");
            this.getprevyear()
          } else {
            // this.oomdataTourn = response.data.ooms.tourn_details;
            if (Array.isArray(response.data.tourns.tourn_details)) {
              console.log("isArray");
              this.oomTournsData = response.data.tourns.tourn_details;
            } else {
              console.log("is not Array");
              this.oomTournsData = [response.data.tourns.tourn_details];
            }
          }

          if (this.enhancedPlayerInfo.ooms.data_format == "") {
            console.log("tourns no data");
          } else {
            // this.oomdataTourn = response.data.ooms.tourn_details;
            if (Array.isArray(response.data.ooms.tourn_details)) {
              console.log("isArray");
              this.oomData = response.data.ooms.tourn_details;
            } else {
              console.log("is not Array");
              this.oomData = [response.data.ooms.tourn_details];
            }
          }

          if (this.enhancedPlayerInfo.stats.data_format == "") {
            // console.log()
          } else {
            this.playerStats = response.data.stats.tourn_details;
            if (Array.isArray(this.playerStats)) {
              console.log("isArray");
            } else {
              console.log("is not Array");
              this.playerStats = [this.playerStats];
            }
          }
        }
      });
  },
  created() {
    axios
      .get(
        process.env.VUE_APP_LETAS_TMX_PLAYER +
          this.season +
          "/" +
          this.season +
          "-" +
          "players-" +
          this.refno +
          "?randomadd=" +
          new Date().getTime()
      )
      .then(response => {
        if (!Object.keys(response.data).length) {
          console.log("True");
        } else {
          this.enhancedPlayerInfoLAS = response.data;
          if (this.enhancedPlayerInfoLAS.tourns.data_format == "") {
            console.log("tourns no data");
          } else {
            // this.oomdataTourn = response.data.ooms.tourn_details;
            if (Array.isArray(response.data.tourns.tourn_details)) {
              console.log("isArray");
              this.oomTournsDataLETAS = response.data.tourns.tourn_details;
            } else {
              console.log("is not Array");
              this.oomTournsDataLETAS = [response.data.tourns.tourn_details];
            }
          }

          if (this.enhancedPlayerInfo.ooms.data_format == "") {
            console.log("tourns no data");
          } else {
            // this.oomdataTourn = response.data.ooms.tourn_details;
            if (Array.isArray(response.data.ooms.tourn_details)) {
              console.log("isArray");
              this.oomData = response.data.ooms.tourn_details;
            } else {
              console.log("is not Array");
              this.oomData = [response.data.ooms.tourn_details];
            }
          }

          if (this.enhancedPlayerInfo.stats.data_format == "") {
            // console.log()
          } else {
            this.playerStats = response.data.stats.tourn_details;
            if (Array.isArray(this.playerStats)) {
              console.log("isArray");
            } else {
              console.log("is not Array");
              this.playerStats = [this.playerStats];
            }
          }
        }
      });
  }
};
</script>

<style scoped>
.row.bagPad {
  padding: 18px;
}
.MybagBlock:nth-child(1),
.MybagBlock:nth-child(2),
.MybagBlock:nth-child(3),
.MybagBlock:nth-child(7),
.MybagBlock:nth-child(8),
.MybagBlock:nth-child(9),
.MybagBlock:nth-child(13),
.MybagBlock:nth-child(14),
.MybagBlock:nth-child(15)
{
  background-color: #a9dbff;
}
.topSectionInfo {
  height: 288px;
}
button.btn.namePronounce.btn-secondary {
  background-color: #01477b;
  padding: 0.275rem 0.75rem;
}
li.nav-item.websiteLink > a {
  color: #212529;
}
li.nav-item.websiteLink > a:hover {
  color: #015699;
}
::v-deep td.tournNameLetas {
  display: block;
  padding: 0.5rem 1rem !important;
}
::v-deep .custom-select {
  margin-top: 9px;
  margin-left: -9px;
}
::v-deep .tr-LET {
  background-color: rgba(0, 0, 0, 0.05);
}
::v-deep img.img-fluid.postImg {
  width: 100%;
  padding-right: 20px;
}
::v-deep .RightDetails {
  text-align: right;
}
::v-deep .InfoB {
  background-color: #f2f2f2;
}
::v-deep .tourCol {
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 20px;
}
.pic {
  padding-bottom: 2em !important;
}
::v-deep li.nav-item.titleBlut-Tab.SeeMore > a {
  background-color: #015699;
}
::v-deep td {
  padding: 0.25rem !important;
  vertical-align: middle !important;
  border-top: 0 solid #dee2e6;
  font-size: 1rem;
}
li.statsTournLET > a {
  color: #212529;
}
li.statsTournLET > a:hover {
  color: #015699;
}
tr:hover {
  background-color: #f6f5f5;
}
td.tournName {
  /* text-decoration: underline; */
}
.CareerRankings_Block {
  margin-bottom: 30px;
  margin-top: 40px;
}
.SeasonRankingsTop_three {
  margin-bottom: 30px;
}
.SeasonPlayerStats {
  margin-bottom: 20px;
}
h4.Player-stats-value {
  font-size: 25pt;
  margin-top: 0px;
  color: #015699;
  float: right;
  margin-bottom: 0px;
}
h3.playerprofile-largeStat {
  font-size: 40pt;
  font-weight: 500;
  color: #015699;
}
h5.Player-stats {
  font-size: 1rem;
  color: #333;
  margin-bottom: 0;
}
span.cupPlayerProfile {
  margin-left: 15px;
}
img.imageCup_playerProfile {
  width: 20px;
  margin-top: 10px;
}
.RankTitle {
  color: #015699;
  font-size: 2rem;
  margin-bottom: 10px;
  margin-top: 10px;
}
.MybagBlock {
  /* margin-top: 15px;
  margin-bottom: 15px; */
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid #e8f6ff;
}
::v-deep b,
strong {
  font-weight: 500 !important;
}
.firstRow-flickrPlayer {
  margin-top: 3.1em;
}
.flickrouter {
  margin-bottom: 0 !important;
}
.brandItem-name {
  font-size: 1rem;
  color: #333;
  margin-bottom: 0;
}
.Club-manufacturer {
  color: #015699;
}
span.ranking-playerProfile {
  font-size: 10pt;
  color: #a5a5a5;
  font-weight: 350;
}
::v-deep .bioSection > span.Bio > p {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 2.1;
}
::v-deep .bioSection > span.Bio > ul > li {
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 2.1;
}
::v-deep li.titleBlut-Tab > a {
  /* background-color: #015699!important; */
}
::v-deep li.playerstatsTitle > a {
  background-color: #01477b !important;
}
::v-deep .miniWrapTour {
  position: absolute;
  bottom: -8px;
  width: 94.5%;
}
::v-deep .tourNews {
  color: #fff;
  font-size: 10pt;
  margin-top: 0;
  padding: 10px;
  background-color: #01477b;
}
li.twitterLogo > a {
  padding-left: 0px;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: none;
  background-color: #01477b;
}
::v-deep .nav-tabs .nav-link:hover,
.nav-tabs .nav-item.show .nav-link {
  background-color: #015699;
}
li {
  list-style: none;
}
.playerBlock {
  margin-top: 100px;
  margin-bottom: 100px;
}
.backPlayer {
  /* background-color: #01477b; */
  background-repeat: no-repeat;
  background-size: cover;
}
.playerImg {
  width: 100%;
  position: absolute;
  top: -93px;
  left: 0;
}
.blockInfo {
  margin-top: 2.5em;
  padding-bottom: 1.85em;
  padding-left: 40px;
  padding-right: 40px;
}
.ppFullName {
  color: #015699;
  font-size: 3rem;
  font-weight: 400;
}
span.natPP {
  display: inline-flex;
}
.flag {
  width: 50px;
  font-size: 16pt;
  margin-left: 3em;
  font-weight: 100;
  height: 30px;
}
.statInfo {
  display: inline-flex;
}
.ppStat {
  font-size: 44pt;
  font-weight: 600;
  display: flex;
  width: 180px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #015699;
}
.oomName {
  font-size: 8pt;
  color: #a5a5a5;
  font-weight: 500;
  margin-top: 3.5em;
  width: 70px;
  padding-left: 10px;
}
h3 {
  margin-top: 24px;
  margin-bottom: 15px;
}
::v-deep .nav-tabs > .nav-item > a {
  border-bottom: none;
  position: relative;
  top: -2.12em;
  background-color: #015699;
  margin-top: -11px;
  font-size: 1rem;
  padding: 10px 30px;
  color: white;
  border-radius: 0;
  margin-left: 0px;
  border-color: transparent;
}
::v-deep .nav-tabs {
  border: 0;
}
.playerMainStats {
  margin-top: 2em;
  margin-bottom: 4em;
}
.playerDetails {
  line-height: 35px;
  font-size: 1rem;
}
.playerDetails > .nav-item > a {
  /* text-transform: uppercase; */
  padding: 0;
  color: #42a7c6;
}
.twit {
  color: #79a8e6;
  font-size: 15pt;
}
.fb {
  color: #495990;
  font-size: 15pt;
}
.insta {
  color: #c12f64;
  font-size: 15pt;
}
.socialLink {
  font-weight: 400;
  padding-left: 15px;
  text-transform: capitalize;
  font-size: 1rem;
  color: black;
}
span.nameLink {
  color: #212529;
}
span.nameLink:hover {
  color: #015699;
}
.statTag {
  margin-top: 4em;
  padding-bottom: 0em;
  margin-bottom: 3px;
  background-color: #f2f2f2;
}
.playStat {
  font-size: 13pt;
  color: #333;
  margin-bottom: 0;
  line-height: 17.5px;
  margin-top: 24px;
}
.largeStat {
  font-size: 40pt;
  font-weight: 700;
  color: #01477b;
}
.cupPP {
  margin-left: 15px;
}
.imgCup {
  width: 20px;
  margin-top: 10px;
}
.rankingPP {
  font-size: 10pt;
  color: #a5a5a5;
  font-weight: 400;
}
.statPPVal {
  font-size: 25pt;
  margin-top: 12px;
  color: #01477b;
  float: right;
  margin-bottom: 7px;
}
.thisSeaRec {
  margin-top: 4em;
  margin-bottom: 8em;
}
.thispostArea {
  margin-top: 4em;
  margin-bottom: 0.5em;
}
::v-deep .tableList > div > .nav-tabs > .nav-item:nth-child(2) > a {
  background-color: #7ba639;
}
.blockList {
  background-color: #01477b;
  padding: 0 0 0 10px;
}
.blockLetas {
  background-color: #95c93d;
}
.thLetas > th {
  background-color: #7ba639 !important;
}
.trLetas > tr:nth-of-type(odd) {
  background-color: #eaf3dc;
}
.rTag {
  color: #fff;
  padding: 15px 0 15px 0;
  font-size: 13pt;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 400;
}
::v-deep .tableList > div > .nav-tabs > .nav-item > a {
  top: 0;
}
tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0 solid #dee2e6;
  font-size: 1rem;
}
th {
  padding: 0.75rem;
  background-color: #42a7c6;
  color: white;
  font-size: 1rem;
  font-weight: 400;
}
table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
::v-deep .year > .btn {
  display: block;
  width: 130px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid #95c93d; */
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: left;
}
::v-deep .year > .btn::after {
  float: right;
  margin-top: 12px;
}
.dropdown {
  margin-top: 11px;
  margin-left: 12px;
  margin-right: 16px;
  margin-bottom: 11px;
}
@media only screen and (max-width: 768px) {
  .topSectionInfo {
    height: auto!important;
  }
  button.btn.namePronounce.btn-secondary {
    margin-top: -5px;
  }
  ::v-deep .nav-tabs {
    border: 0;
    display: block;
    text-align: center;
  }
  .ppFullName {
    color: #015699;
    font-size: 2rem;
    font-weight: 400;
    /* width: 100%; */
    /* display: inline-block; */
    text-align: center;
  }
  th.hideMob {
    display: none;
  }
  td.hideMob {
    display: none;
  }
  .mainThreeHeadings {
    margin-top: 25px;
  }
  h5.Player-stats {
    font-size: 13pt;
    color: #333;
    margin-bottom: 0;
    text-align: left;
  }
  ::v-deep .miniWrapTour {
    position: absolute;
    bottom: -8px;
    width: 100%;
  }
  ::v-deep img.img-fluid.postImg {
    width: 100%;
    padding-right: 0px;
  }
  ::v-deep.blockInfo {
    margin-top: 1.85em;
    padding-bottom: 0;
    padding-left: 40px;
    padding-right: 40px;
  }
  .mobHide {
    display: none;
  }
  .playerImg {
    position: inherit;
    top: 40px;
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .statPPVal {
    float: left;
  }
  .backPlayer {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
  }
  .oomName {
    font-size: 9pt;
    color: #a5a5a5;
    font-weight: 500;
    margin-top: 0.5em;
    width: auto;
    padding-left: 10px;
    display: block;
    text-align: center;
  }
  .ppStat {
    font-size: 26pt;
    font-weight: 600;
    display: block;
    width: 100%;
    border-bottom: 0px solid rgba(0, 0, 0, 0.1);
    color: #015699;
    text-align: center;
  }
  .statInfo {
    display: block;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  ::v-deep ul#__BVID__122__BV_tab_controls_ {
    display: block;
    text-align: center;
  }
  ::v-deep .nav-tabs > .nav-item > a {
    border-bottom: none;
    position: relative;
    top: -2.12em;
    background-color: #03477b;
    margin-top: 0;
    font-size: 1rem;
    padding: 10px 30px;
    color: white;
    border-radius: 0;
    margin-left: 0px;
    border-color: transparent;
  }
  ::v-deep .socialBlock {
    text-align: center;
    margin-top: 50px;
  }
  ::v-deep .statTag {
    margin-top: 4em;
    padding-bottom: 0em;
    margin-bottom: 3px;
    background-color: #f2f2f2;
    text-align: center;
  }
  ::v-deep h3.playerprofile-largeStat {
    font-size: 20pt;
    font-weight: 500;
    color: #015699;
    text-align: right;
  }
  ::v-deep h4.Player-stats-value {
    font-size: 20pt;
    margin-top: 0;
    color: #015699;
    float: none;
    margin-bottom: 0px;
    text-align: right;
  }
  ::v-deep span.ranking-playerProfile {
    font-size: 10pt;
    color: #a5a5a5;
    font-weight: 500;
    /* text-align: left; */
    display: block;
    text-align: left;
  }
  ::v-deep img.imageCup_playerProfile {
    width: 20px;
    margin-top: 0px;
  }
  ::v-deep .flickrouter {
    padding-bottom: 55px;
    max-height: none;
    overflow: hidden;
    margin-bottom: 0;
  }
  ::v-deep img.playerFlickr {
    overflow: hidden;
    width: 100%;
    max-height: none;
  }
  .flag {
    width: 50px;
    font-size: 16pt;
    margin-left: 0em;
    font-weight: 100;
    height: 30px;
    margin-top: 20px;
  }
  span.natPP {
    display: block;
  }
}
@media only screen and (max-width: 500px) {
  .playerBlock {
    margin-top: 0px;
  }
  .leftAlignLogo > a.nav-link {
    padding-left: 0px;
  }
  .playerImg {
    position: inherit;
    top: 0px;
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .ppFullName {
    color: #015699;
    font-size: 2rem;
    font-weight: 400;
    /* width: 100%; */
    /* display: inline-block; */
    text-align: center;
  }
  .flag {
    width: 50px;
    font-size: 16pt;
    margin-left: 0em;
    font-weight: 100;
    height: 30px;
    margin-top: 20px;
  }
  span.natPP {
    display: block;
  }
  .dropdown {
    margin-top: 11px;
    margin-left: 12px;
    margin-right: 16px;
    margin-bottom: 11px;
    width: 90%;
  }
  .Mobdate {
    padding-left: 0px;
  }
  .mobCenter {
    text-align: center;
  }
  ::v-deep .nav-tabs {
    border: 0;
    display: block;
    text-align: center;
  }
}
</style>