<template>
  <div>
    <div
      v-bind:class="{'flickrTourn': this.$route.name === 'Tournament'}"
      id="flickr"
      v-if="this.$route.name === 'Tournament' || this.$route.name === 'home' || this.$route.name === 'team-europe'"
    >
      <b-container>
        <b-row>
          <b-col>
            <b-button v-bind:class="{'flickrTourn-Only': this.$route.name === 'Tournament'}" v-if="this.$route.name === 'Tournament'" class="allNews"
              >See All Images</b-button
            >
            <!-- <b-button v-if="this.$route.name === 'home' " class="allNews"
              >See All Photos</b-button
            > -->
            <b-link href="https://www.flickr.com/photos/ladieseuropeantour/" target="_blank">
              <b-button v-if="this.$route.name === 'team-europe' || this.$route.name === 'home'" class="euroNews" :class="{seePhotosHome : this.$route.name === 'home'}">See All Photos
              </b-button>
            </b-link>
          </b-col>
        </b-row>
        <b-row v-if="this.$route.name === 'Tournament'">
          <b-col
            class="imgColTourn flickrImage-tourn"
            lg="2"
            v-for="(photo, index) in flickr.photos.photo.slice().reverse()"
            :key="index"
          >
          <a :href="'https://www.flickr.com/photos/'+config.VUE_APP_FLICKR_NAME+ '/' + photo.id" target="_blank">
            <b-img
              class="fImg"
              :src="
                'https://farm' +
                  photo.farm +
                  '.staticflickr.com/' +
                  photo.server +
                  '/' +
                  photo.id +
                  '_' +
                  photo.secret +
                  '.jpg'
              "
            ></b-img>
          </a>
          </b-col>
        </b-row>
        <b-row v-if="this.$route.name === 'home'" class="homeFlicker">
          <b-col
            class="imgCol"
            lg="4"
            v-for="(photo, index) in flickr.photos.photo"
            :key="index"
          ><a :href="'https://www.flickr.com/photos/'+config.VUE_APP_FLICKR_NAME+'/' + photo.id" target="_blank">
            <b-img
              class="fImg"
              :src="
                'https://farm' +
                  photo.farm +
                  '.staticflickr.com/' +
                  photo.server +
                  '/' +
                  photo.id +
                  '_' +
                  photo.secret +
                  '.jpg'
              "
            ></b-img>
            </a>
          </b-col>
        </b-row>
        <b-row v-if="this.$route.name === 'team-europe'">
          <b-col
            class="imgCol"
            lg="4"
            v-for="(photo, index) in flickr.photoset.photo"
            :key="index"
          ><div class='outerEuro'>
            <a :href="'https://www.flickr.com/photos/'+config.VUE_APP_FLICKR_NAME+'/' + photo.id" target="_blank">
            <b-img
              class="fImg"
              :src="
                'https://farm' +
                  photo.farm +
                  '.staticflickr.com/' +
                  photo.server +
                  '/' +
                  photo.id +
                  '_' +
                  photo.secret +
                  '.jpg'
              "
            ></b-img></a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-if="this.$route.name === 'player-profiles'">
      <b-row>
        <b-col lg='6' md='12' >
          <template v-if="flickr.photos.photo[0].url_z.length">
            <img class="FlickrMain-image" :src="flickr.photos.photo[0].url_z">
          </template>
          <template v-else>
            <img class="FlickrMain-image" :src="'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/05/largelogo-newsIndiviudel.png'">
          </template>
        </b-col>
        <b-col>
          <b-row>
            <b-col lg='4'
              class="flickrouter"
              v-for="(photo, index) in flickr.photos.photo.slice(0,3)" :key="index"
            >
              <img class="FlickrMini-image playerFlickr" :src="photo.url_z" @click="$bvModal.show(photo.url_z)">
              <b-modal :id="photo.url_z" title="BootstrapVue" hide-header centered hide-footer>
                <img class="FlickrMini-image playerFlickrModal" :src="photo.url_z">
                <h3 class="photoText">{{photo.title}}</h3>
              </b-modal>
            </b-col>
          </b-row>
          <b-row class="firstRow-flickrPlayer">
            <b-col lg='4'
              class="flickrouter"
              v-for="(photo, index) in flickr.photos.photo.slice(3,6)" :key="index"
            >
              <img class="FlickrMini-image playerFlickr" :src="photo.url_z" @click="$bvModal.show(photo.url_z)">
              <b-modal :id="photo.url_z" title="BootstrapVue" hide-header centered hide-footer>
                <img class="FlickrMini-image playerFlickrModal" :src="photo.url_z">
                <h3 class="photoText">{{photo.title}}</h3>
              </b-modal>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "flickr",
  props: ["tourName", "first_name", "surname", "refno", 'config'],
  computed: {
    homeOrTour(url) {
      if (this.$route.name === "Tournament")
        url =
          process.env.VUE_APP_FLICKR_API+"?method=flickr.photos.search&api_key="+process.env.VUE_APP_FLICKR_KEY_ONE+"&extras=url_z&per_page=50&text=" +
          this.tourName +
          "&user_id=99039037@N06&format=json&nojsoncallback=?";
      else if (this.$route.name === "player-profiles")
        url =
          process.env.VUE_APP_FLICKR_API+"?method=flickr.photos.search&api_key="+process.env.VUE_APP_FLICKR_KEY_ONE+"&extras=url_z&per_page=6&text=" +
          this.surname +
          "+" +
          this.first_name +
          "&user_id=99039037@N06&format=json&nojsoncallback=?";
          else if (this.$route.name === "team-europe")
          url = process.env.VUE_APP_FLICKR_API+"?method=flickr.photosets.getPhotos&api_key="+process.env.VUE_APP_FLICKR_KEY_TWO+"&per_page=6&photoset_id=72177720311306845&user_id=99039037@N06&extras=url_o&format=json&nojsoncallback=?";
      else
        url =
          process.env.VUE_APP_FLICKR_API+"?method=flickr.people.getPublicPhotos&api_key="+process.env.VUE_APP_FLICKR_KEY_TWO+"&per_page=6&user_id=99039037@N06&format=json&nojsoncallback=?";
      return url;
    },
  },
  data() {
    return {
      flickr: [],
    };
  },
  mounted() {
    axios //add API Call
      .get(this.homeOrTour + "&randomadd=" + new Date().getTime()) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.flickr = response.data;
      });
  },
};
</script>

<style scoped>
.photoText {
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 15px;
}
button.btn.euroNews.btn-secondary.seePhotosHome {
  background-color: #01477b;
  border-radius: 0;
  padding: 11px 30px;
  margin-top: 15px;
}
::v-deep button.btn.euroNews.btn-secondary.seePhotosHome:hover {
  background-color: #015699;
  border-radius: 0;
  padding: 11px 30px;
  margin-top: 15px;
}
.row.homeFlicker {
  padding-bottom: 50px;
  padding-top: 20px;
}
.firstRow-flickrPlayer {
  margin-top: -0.4em;
}
img.playerFlickr {
  overflow: hidden;
  width: 100%;
  max-height: 235px;
}
img.playerFlickrModal {
  overflow: hidden;
  width: 100%;
}
.flickrouter {
  padding-bottom: 55px;
  max-height: 106px;
  overflow: hidden;
  margin-bottom: 3.5em;
}
.firstRow-flickr {
  margin-top: 3.1em;
}
.FlickrMini-image {
  width: 100%;
}
.FlickrMain-image {
  width: 100%;
}
::v-deep .imgCol {
  padding: 20px 20px;
  height: 250px;
  overflow: hidden;
  margin-bottom: 10px;
}
.fImg {
  width: 100%;
}
.flickrImage-tourn {
  height: auto;
  margin-bottom: 0px;
}
.imgCol {
  padding: 10px 15px;
  overflow: hidden;
  /* height: 100px; */
}
.imgColTourn {
  padding: 20px 20px;
  overflow: hidden;
  height: 100px;
}
.flickrTourn-Only {
  margin-bottom: 30px;
}
.flickrTourn {
  padding: 30px 0!important;
}
#flickr {
  background-color: #f8f8f8;
  padding: 15px;
  /* padding: 50px 0px; */
  margin-bottom: 30px;
}
.allNews {
  float: right;
  background-color: #01477b;
  border-radius: 0;
  padding: 11px 30px;
  border-color: #01477b;
}
.euroNews {
  float: right;
  background-color: #181797;
  border-radius: 0;
  padding: 11px 30px;
}
.outer{
  overflow: hidden;
  width: 100%;
  max-height: 100px;
}
.outerEuro{
  overflow: hidden;
  max-height: 231px;
  width: 100%; 
}
.player{
  padding-bottom: 40px;
}
@media only screen and (max-width: 768px) {
  .outer{
    overflow: visible;
    width: 100%;
    max-height: max-content;
  }
  .imgCol {
    height: fit-content;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .socialTitle {
    margin-right: 30px;
    margin-bottom: 20px;
  }
}

</style>
