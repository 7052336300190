<template class="TableSTatssss">
  <div class="TableStat">
    <div class="statTag">
      <h3 class="statTitle" colspan="11">
        {{ data.full_name }} - {{data.last_tournament}}
      </h3>
    </div>
    <table>
      <thead>
        <tr class='playerProfileTitle'>
          <th>
            Pos
          </th>
          <th>
            Name
          </th>
          <th>
            Nat
          </th>
          <th>
            {{data.value_1_head}}
          </th>
          <th>
            {{data.oom_header}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(stats, index) in data.oom.oom_entry" :key="index">
          <td>
            {{ stats.pos }}
          </td>
          <td>
            <a :href="'/player-profiles/' + stats.playerno + '/'">
              {{ stats.name }}
            </a>
          </td>
          <td>
            <span>
              <img
                class="flag"
                :src="
                  (config.VUE_APP_FLAG_URL +
                    stats.nationality)
                    | lowercase
                "
              />
            </span>
          </td>
          <td>
            {{ stats.oom_value }}
          </td>
          <td>
            {{ stats.played }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "statsTable",
  props: ["id", "stat", "data", 'config'],
  data() {
    return {};
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
.flag {
  width: 30px;
  box-shadow: 0 0 1px #555;
}
.statTag {
  background-color: #01477b;
  padding: 0 0 0 10px;
  margin-top: 3px;
}
.statTitle {
  color: #fff;
  padding: 15px 0 15px 0;
  font-size: 13pt;
  margin-top: 9px;
  margin-bottom:0;
}
table{
  margin-bottom: 8em;
  width: 100%;
  color: #212529;
}
.playerProfileTitle{
  background-color: #42a7c6!important;
  color: #fff;
}
th{
  padding: .75rem;
  font-size: 1rem;
  font-weight: 500;
}
td{
  padding: .75rem;
  font-size: 1rem;
}
td>a{
  color: black;
  /* text-decoration: underline; */
}
tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05);
}

</style>
