<template>
  <div class="youTube">
    <div v-if="this.$route.query.id === ''">
      <YouTube :id='this.$route.query.id' :config="config"/>
    </div>
    <div v-else>
      <YouTube :id='this.$route.query.id' :config="config"/>
    </div>
  </div>
</template>
<script>
import YouTube from '@/components/youTube.vue'

export default {
  name:'video',
  props:['config'],
  components:{
    YouTube
  }
}
</script>

<style scoped>
 .youTube{
   padding: 60px 100px;
 }
 @media only screen and (max-width: 768px) {
 .youTube{
   padding: 10px 10px;
 }
}

</style>
