<template>
  <div class="home">
    <div id="banner">
      <b-img
        class="splashImg"
        :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/01/developemntsec.png'"
      ></b-img>
    </div>

    <b-tabs nav-wrapper-class="container">
      <b-tab title="LET'S INSPIRE" active>
        <div id="inspire">
          <b-container>
            <div class="height">
              <h1 class="tabTitle">
                {{ wpData.acf.letsinspire_title }}
              </h1>
              <p class="tabDes"></p>
              <span
                v-html="wpData.acf.letsinspire_descript"
                class="tabDes"
              ></span>
            </div>
          </b-container>
        </div>
      </b-tab>
      <b-tab title="LET'S PLAY">
        <div id="play">
          <b-container>
            <div class="height">
              <h1 class="tabTitle">
                {{ wpData.acf.letsplay_title }}
              </h1>
              <p class="tabDes"></p>
              <span v-html="wpData.acf.letsplay_descript" class="tabDes"></span>
            </div>
          </b-container>
        </div>
      </b-tab>
      <b-tab title="LET'S GET READY">
        <div id="ready">
          <b-container>
            <div class="height">
              <h1 class="tabTitle">
                {{ wpData.acf.letsgetready_title }}
              </h1>
              <p class="tabDes"></p>
              <span
                v-html="wpData.acf.letsgetready_descript"
                class="tabDes"
              ></span>
            </div>
          </b-container>
        </div>
      </b-tab>
      <b-tab title="LET'S PASS IT ON">
        <div id="pass">
          <b-container>
            <div class="height">
              <h1 class="tabTitle">
                {{ wpData.acf.letslearn_title }}
              </h1>
              <p class="tabDes"></p>
              <span
                v-html="wpData.acf.letslearn_descript"
                class="tabDes"
              ></span>
            </div>
          </b-container>
        </div>
      </b-tab>
      <b-tab title="LET'S GO GREEN">
        <div id="green">
          <b-container>
            <div class="height">
              <h1 class="tabTitle">
                {{ wpData.acf.letsgiveback_title }}
              </h1>
              <p class="tabDes"></p>
              <span
                v-html="wpData.acf.letsgiveback_descript"
                class="tabDes"
              ></span>
            </div>
          </b-container>
        </div>
      </b-tab>
      <b-tab title="LET'S KEEP IN TOUCH">
        <div id="touch">
          <b-container>
            <div class="height">
              <h1 class="tabTitle">
                {{ wpData.acf.letskeepintouch_title }}
              </h1>
              <p class="tabDes"></p>
              <span
                v-html="wpData.acf.letskeepintouch_descript"
                class="tabDes"
              ></span>
            </div>
          </b-container>
        </div>
      </b-tab>
      <b-tab title="PLAYERS HUB">
        <div class="hub">
          <b-container>
            <div class="height">
              <h1 class="tabTitle">
                {{ wpData.acf.letsplayershub_title }}
              </h1>
              <p class="tabDes"></p>
              <span
                v-html="wpData.acf.letsplayershub_descipt"
                class="tabDes"
              ></span>
              <b-row class='rP'>
                <b-col>
                  <b-card
                    :header="wpData.acf.playershub_one_title"
                    :img-src="wpData.acf.playershub_one_image"
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="mb-2"
                  >
                    <b-card-text v-html='wpData.acf.playershub_one_content'>
                    </b-card-text>

                    <b-button class="hub" :to="'/rookie/89992'">
                      MORE INFO
                    </b-button>
                  </b-card>
                </b-col>
                <b-col>
                  <b-card
                    :header="wpData.acf.playershub_two_title"
                    :img-src="wpData.acf.playershub_two_image"
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="mb-2"
                  >
                    <b-card-text v-html='wpData.acf.playershub_two_content'>
                    </b-card-text>

                    <b-button class="hub" :to="'/rookie/90001'">MORE INFO</b-button>
                  </b-card>
                </b-col>
                <b-col>
                  <b-card
                    :header="wpData.acf.playershub_three_title"
                    :img-src="wpData.acf.playershub_three_image"
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="mb-2"
                  >
                    <b-card-text v-html='wpData.acf.playershub_three_content'>
                    </b-card-text>

                    <b-button class="hub" :to="'/rookie/90006'">MORE INFO</b-button>
                  </b-card>
                </b-col>
              </b-row>
              <b-row class='rP'>
                <b-col>
                  <b-card
                    :header="wpData.acf.playershub_four_title"
                    :img-src="wpData.acf.playershub_four_image"
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="mb-2"
                  >
                    <b-card-text v-html='wpData.acf.playershub_four_content'>
                    </b-card-text>

                    <b-button class="hub" :to="'/rookie/90012'">MORE INFO</b-button>
                  </b-card>
                </b-col>
                <b-col>
                  <b-card
                    :header="wpData.acf.playershub_five_title"
                    :img-src="wpData.acf.playershub_five_image"
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="mb-2"
                  >
                    <b-card-text v-html='wpData.acf.playershub_five_content'>
                    </b-card-text>

                    <b-button class="hub" :to="'/rookie/90018'">MORE INFO</b-button>
                  </b-card>
                </b-col>
                <b-col>
                  <b-card
                    :header="wpData.acf.playershub_six_title"
                    :img-src="wpData.acf.playershub_six_image"
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="mb-2"
                  >
                    <b-card-text v-html='wpData.acf.playershub_six_content'>
                    </b-card-text>

                    <b-button class="hub" :to="'/rookie/90023'">MORE INFO</b-button>
                  </b-card>
                </b-col>
              </b-row>
              <b-row class='rP'>
                <b-col>
                  <b-card
                    :header="wpData.acf.playershub_seven_title"
                    :img-src="wpData.acf.playershub_seven_image"
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="mb-2"
                  >
                    <b-card-text v-html='wpData.acf.playershub_seven_content'>
                    </b-card-text>

                    <b-button class="hub" :to="'/rookie/90029'">MORE INFO</b-button>
                  </b-card>
                </b-col>
                <b-col>
                  <b-card
                    :header="wpData.acf.playershub_eight_title"
                    :img-src="wpData.acf.playershub_eight_image"
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="mb-2"
                  >
                    <b-card-text v-html='wpData.acf.playershub_eight_content'>
                    </b-card-text>

                    <b-button class="hub" :to="'/rookie/90035'">MORE INFO</b-button>
                  </b-card>
                </b-col>
                <b-col>
                  <b-card
                    :header="wpData.acf.playershub_nine_title"
                    :img-src="wpData.acf.playershub_nine_image"
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="mb-2"
                  >
                    <b-card-text v-html='wpData.acf.playershub_nine_content'>
                    </b-card-text>

                    <b-button class="hub" :to="'/rookie/90041'">MORE INFO</b-button>
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </b-container>
        </div>
      </b-tab>
    </b-tabs>
    <div>
      <b-container class='sponSec'>
        <h3 class='offSponTitle'>
          OFFICIAL SUPPORTERS AND DEVELOPMENT PARTNERS
        </h3>
        <b-row>
          <b-col lg='3' md='12' cols='12'>
            <b-img class="devImage" :src='wpData.acf.official_partners_and_supports_of_development_one'></b-img>
          </b-col>
          <b-col lg='3' md='12' cols='12'>
            <b-img class="devImage" :src='wpData.acf.official_partners_and_supports_of_development_two'></b-img>
          </b-col>
          <b-col lg='6' md='12' cols='12' class='yt'>
            <span v-html='wpData.acf.youtube_video_one'></span>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg='3' md='12' cols='12'>
            <b-img class="devImage" :src='wpData.acf.official_partners_and_supports_of_development_three'></b-img>
          </b-col>
          <b-col lg='3' md='12' cols='12'>
            <b-img class="devImage" :src='wpData.acf.official_partners_and_supports_of_development_four'></b-img>
          </b-col>
          <b-col lg='6' md='12' cols='12'>
            <span v-html='wpData.acf.youtube_video_two'></span>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class='grey'>
      <Post />
    </div>
    <div class='rPad'>
      <b-container>
        <b-row>
          <b-col class='colPad' lg='4' v-for="(pics, index) in devPin" :key='index'>
            <b-img class='photoCols' :src='pics.acf.image'></b-img>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Post from '@/components/posts.vue'
export default {
  name: "Development",
  props: ["course", "season", "report", 'config'],
  components:{Post},
  data: function() {
    return {
      wpData: [],
      devPin: [],
    };
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/132" + "?randomadd=" + new Date().getTime())
      .then((response) => {
        this.wpData = response.data;
        return axios.get(
          process.env.VUE_APP_WPAPI_URL+"wp/v2/development"
        );
      })
      .then((response) => {
        this.devPin = response.data;
      });
  },
};
</script>
<style scoped>
a.btn.hub.btn-secondary:hover{
  background-color: #015699;
}
a.btn.hub.btn-secondary {
  width: 100%;
}
img.devImage {
  width: 100%;
}
#banner {
  overflow: hidden;
  /* height: 640px; */
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/01/developemntsec.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splashImg {
  width: 100%;
}
::v-deep .nav-tabs {
  border-bottom: 0px solid #181797;
  margin-top: -2.4em;
  z-index: 99;
  position: relative;
}
#inspire {
  background: #0056b5;
}
#play {
  background: #e55c3a;
}
#ready {
  background: #969799;
}
#pass {
  background: #51aee7;
}
#green {
  background: #a9d42d;
}
#touch {
  background: #f8c746;
}
.hub {
  background: #194778;
}
::v-deep .nav-tabs > li:nth-child(1) {
  background: #0056b5;
}
::v-deep .nav-tabs > li:nth-child(2) {
  background: #e55c3a;
}
::v-deep .nav-tabs > li:nth-child(3) {
  background: #969799;
}
::v-deep .nav-tabs > li:nth-child(4) {
  background: #51aee7;
}
::v-deep .nav-tabs > li:nth-child(5) {
  background: #a9d42d;
}
::v-deep .nav-tabs > li:nth-child(6) {
  background: #f8c746;
}
::v-deep .nav-tabs > li:nth-child(7) {
  background: #194778;
}
::v-deep .nav-tabs > li {
  padding: 10px 0;
}
::v-deep .nav-tabs > li > a {
  color: #fff;
  width: 150px;
  display: block;
  font-size: 1rem;
  text-align: center;
  border-radius: 0;
  padding: 0;
}
::v-deep .nav-link.active {
  color: white;
  background-color: transparent;
  border-color: transparent;
}
.height {
  height: auto;
  padding-bottom: 5em;
}
.tabTitle {
  color: #fff;
  padding-top: 2em;
}

::v-deep .tabDes > p {
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  text-transform: initial;
  font-family: Helvetica, Arial !important;
  line-height: 32px;
}
.rP{
  padding: 10px 0;
}
.card-body{
  height: 400px;
  font-size:1rem
}
.offSponTitle{
  color: #015699;
  margin-bottom: 2em;
  font-size: 17pt;
}
.sponSec{
  padding-top: 100px;
}
.grey{
  background-color: #f6f6f6;
  padding: 70px 0;
}
.photoCols{
  height: 234px;
}
.colPad{
  padding: 25px 0;
}
.rPad{
  padding: 50px 0;
}

@media only screen and (max-width: 1200px) {
  ::v-deep .nav-tabs {
    border-bottom: 0px solid #181797;
    margin-top: -2.15em;
    z-index: 99;
    position: relative;
  }
  ::v-deep .nav-tabs > li > a {
    color: #fff;
    width: 130px;
    display: block;
    font-size: 1rem;
    text-align: center;
    border-radius: 0;
    padding: 0;
    font-size: 10pt;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .nav-tabs>li{
    width: 100%;
    margin-left: 0;
  }
  .tabs{
    background-color: #0056b5;
  }
  .card-img, .card-img-top, .card-img-bottom {
    flex-shrink: 0;
    width: auto!important;
  }
  ::v-deep .nav-tabs > li > a {
    text-align: left;
    padding-left: 15px;
  }  
}
@media only screen and (max-width: 425px) {
  ::v-deep img.devImage {
    width: 200px;
    margin-bottom: 30px;
  }
  ::v-deep .yt>span>iframe{
    width: 400px!important;
  }
  ::v-deep .photoCols {
    height: 234px;
    text-align: center;
    margin: auto;
    display: block;
  }
}
</style>
