<template>
  <div class="stats">
      <div>
        <b-img
          class="splash"
          :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/01/stats-1.png'"
        ></b-img>
      </div>
      <b-container>
        <b-row>
          <b-col>
            <a href="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2024/07/LET-Strokes-Gained-Explainer.jpg" target="_blank">
              <div class="schedule-dropdown schedule_block_explained">
                Strokes Gained Explained
              </div>
            </a>
          </b-col>
          <b-col>
            <!-- <div class="schedule_block">
              <b-form-select v-model="id" class="schedule-dropdown" v-on:change="changeYear(id)">
                <b-form-select-option 
                  class="opt-arrow"
                  v-for="(value, index) in data.slice().reverse()"
                  :key="index"
                  :value="value.code"
                >{{value.desc}}</b-form-select-option>
              </b-form-select>
            </div> -->
          </b-col>
          <!-- <b-col>
            <a href="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2024/07/LET-Strokes-Gained-Explainer.jpg" target="_blank">
              <div class="schedule-dropdown schedule_block_explained">
                Strokes Gained Explained
              </div>
            </a>
          </b-col> -->
        </b-row>
        <div v-for="(stat, index) in FullStats" :key="index">
          <div v-if="stat.show_app !== 'A' && stat.show_app !== 'N'">
            <MiniStat 
              :statcode="stat.code" 
              :config="config"
              :id="id"
              :year="year"
              :data="data"
              />
          </div>
        </div>
        <!-- strokes gained section -->
        <!-- <div id="StrokesGainedTotal">
          <MiniStat :statData="u6" :config="config"/>
        </div>
        <div id="StrokesGainedDrive">
          <MiniStat :statData="u7" :config="config"/>
        </div>
        <div id="StrokesGainedApproach">
          <MiniStat :statData="u8" :config="config"/>
        </div>
        <div id="StrokesGainedShort">
          <MiniStat :statData="u9" :config="config"/>
        </div>
        <div id="StrokesGainedPutting">
          <MiniStat :statData="ua" :config="config"/>
        </div> -->
      </b-container>
    </div>
</template>

<script>
import axios from "axios";
import MiniStat from "@/components/miniStat.vue";
//import StatsTable from "@/components/statsTable.vue";

export default {
  name: "stats",
  props:['config'],
  components: {
    MiniStat,
    //    StatsTable,
  },
  data() {
    return {
      id: this.$route.query.id,
      wpPage: [],
      data: [],
      year: this.$route.query.id,
      FullStats: [],
      statCode: '',
      statdata: [],
    };
  },
  methods:{
    changeYear: function(id) {
      axios
        .get(
          process.env.VUE_APP_TIC_BASE +
            id +
            "/" +
            id +
            "-stats-stats-" +
            this.statcode +
            ".json?randomadd=" +
            new Date().getTime()
        )
        .then((response) => {
          this.statdata = response.data;
          console.log("this.statdata")
          console.log(this.statdata)
        })
    }
  },
  mounted() {
    axios.get(
      process.env.VUE_APP_TIC_BASE + this.id  + '/' +  "tmticx?randomadd=" +
        new Date().getTime()
    )
    .then((response) => {
      this.data = response.data.seasons.seasons_entry;
      this.FullStats = response.data.stats.stats_entry;
      return axios.get(
        process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/130"
      );
    })
    .then((response) => {
      this.wpPage = response.data;
    });
  }
};
</script>

<style scoped>
.schedule-dropdown.schedule_block_explained {
  background: #03569a;
  padding: 5px;
  width: 300px;
  text-align: center;
  position: absolute;
  top: -42px;
  color: #fff;
  font-size: 1rem;
  height: 42px;
  line-height: 2;
  left: -2px;
}
.schedule-dropdown.schedule_block_explained:hover {
  background: #01477b;
  padding: 5px;
  width: 300px;
  text-align: center;
  position: absolute;
  top: -42px;
  color: #fff;
  font-size: 1rem;
  height: 42px;
  line-height: 2;
  left: -2px;
}
::v-deep .schedule_block > select {
  background-color: #015699!important;
}
::v-deep select.schedule-dropdown {
  width: 300px;
  border: none;
  border-radius: 0;
  padding: 10px;
  color: #fff;
  top: -42px;
  position: relative;
  left: -20px;
  text-align: left;
  height: 42px;
  background: #fff url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/DownArrow.png') right 0.75rem center/12px 7px no-repeat;
}
@media only screen and (max-width: 990px) { 
  ::v-deep div#driveDist, 
  ::v-deep div#driveAcc, 
  ::v-deep div#sandSav, 
  ::v-deep div#puttAv, 
  ::v-deep div#greenReg, 
  ::v-deep div#strokeAv, 
  ::v-deep div#totalEag, 
  ::v-deep #totalBird {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 550px) { 
  ::v-deep .rankOne {
    color: #fff;
    position: absolute;
    bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15pt;
    top: -50px!important;
    background: #eeb500;
    width: 100%;
    height: 50px;
    /* padding-top: 15px; */
    padding-top: 10px;
    padding-bottom: 15px;
  }
  ::v-deep .statRank {
    margin-top: 80px!important;
    margin-bottom: 3em;
    padding: 0!important;
  }
  ::v-deep select.schedule-dropdown {
    width: 100%;
    background: #015699;
    border: none;
    border-radius: 0;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    top: -42px;
    position: relative;
    left: 0;
    text-align: left;
    height: 42px;
  }
}

@media only screen and (max-width: 425px) { 

}
.splash {
  width: 100%;
}
::v-deep .year > .btn {
  width: 300px;
  -webkit-appearance: none;
  background: #015699;
  border: none;
  border-radius: 0;
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  top: -44px;
  position: relative;
  left: -20px;
  text-align: left;
}
::v-deep .year > .btn::after {
  float: right;
  margin-top: 12px;
}
</style>
