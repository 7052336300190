var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reports"},[(_vm.forceScoring)?_c('div',{staticClass:"main home-buttons",class:{widgetTakeover: _vm.takeover == true}},[(_vm.multi_tourns == 'Y' && _vm.home === true)?[_c('b-row',{staticClass:"pastbtnRow",class:{pastBTnPos: _vm.takeover == true}},[_vm._l((_vm.past_tourns),function(past,index){return _c('b-col',{key:index,staticClass:"tabBtn"},[_c('b-button',{staticClass:"pastbtn",class:{
              activeBtn: _vm.course === past.code
            },on:{"click":function($event){return _vm.swapTour(past.code)}}},[_vm._v(_vm._s(past.name))])],1)}),_c('b-col',{staticClass:"tabBtn"},[_c('b-button',{staticClass:"pastbtn",class:{ activeBtn: _vm.course === _vm.tm.code },on:{"click":function($event){return _vm.swapTour(_vm.tm.code)}}},[_vm._v(_vm._s(_vm.tm.short_name))])],1),(_vm.tm.future_tourns !== '')?_c('b-col',{staticClass:"tabBtn"},[_c('b-link',{attrs:{"to":'tournaments-information/' +
                _vm.tm.future_tourns.future_tourns_entry.code +
                '/' +
                this.season}},[_c('b-button',{staticClass:"pastbtn",class:{
                activeBtn:
                  _vm.course === _vm.tm.future_tourns.future_tourns_entry.code
              },on:{"click":function($event){return _vm.swapTour(_vm.tm.future_tourns.future_tourns_entry.code)}}},[_vm._v(_vm._s(_vm.tm.future_tourns.future_tourns_entry.name))])],1)],1):_vm._e()],2)]:_vm._e(),_c('ReportTable',{attrs:{"multi_tourns":_vm.multi_tourns,"pastT":_vm.pastT,"tm":_vm.tm,"head":_vm.head,"data":_vm.data,"home":_vm.home,"title":_vm.title,"report":_vm.report,"season":_vm.season,"code":_vm.course,"takeover":_vm.takeover,"config":_vm.config,"team":_vm.teamEvent,"live":_vm.liveOn,"match":_vm.match,"days_away":_vm.days_away,"live_scoring":_vm.live_scoring,"web_sponsor":_vm.web_sponsor}})],2):_c('div',{staticClass:"main home-buttons",class:{widgetTakeover: _vm.takeover == true}},[(_vm.showTourn)?_c('div',[(_vm.multi_tourns == 'Y' && _vm.home === true)?[_c('b-row',{staticClass:"pastbtnRow",class:{pastBTnPos: _vm.takeover == true}},[_vm._l((_vm.past_tourns),function(past,index){return _c('b-col',{key:index,staticClass:"tabBtn",attrs:{"lg":"6"}},[_c('b-button',{staticClass:"pastbtn",class:{
                activeBtn: _vm.course === past.code
              },on:{"click":function($event){return _vm.swapTour(past.code)}}},[_vm._v(_vm._s(past.name))])],1)}),_c('b-col',{staticClass:"tabBtn",attrs:{"lg":"6"}},[_c('b-button',{staticClass:"pastbtn",class:{ activeBtn: _vm.course === _vm.tm.code },on:{"click":function($event){return _vm.swapTour(_vm.tm.code)}}},[_vm._v(_vm._s(_vm.tm.short_name))])],1),(_vm.tm.future_tourns !== '')?_c('b-col',{staticClass:"tabBtn"},[_c('b-link',{attrs:{"to":'tournaments-information/' +
                  _vm.tm.future_tourns.future_tourns_entry.code +
                  '/' +
                  this.season}},[_c('b-button',{staticClass:"pastbtn",class:{
                  activeBtn:
                    _vm.course === _vm.tm.future_tourns.future_tourns_entry.code
                },on:{"click":function($event){return _vm.swapTour(_vm.tm.future_tourns.future_tourns_entry.code)}}},[_vm._v(_vm._s(_vm.tm.future_tourns.future_tourns_entry.name))])],1)],1):_vm._e()],2)]:_vm._e(),_c('ReportTable',{attrs:{"multi_tourns":_vm.multi_tourns,"pastT":_vm.pastT,"tm":_vm.tm,"head":_vm.head,"data":_vm.data,"home":_vm.home,"title":_vm.title,"report":_vm.report,"season":_vm.season,"code":_vm.course,"takeover":_vm.takeover,"config":_vm.config,"team":_vm.teamEvent,"live":_vm.liveOn,"match":_vm.match,"days_away":_vm.days_away,"live_scoring":_vm.live_scoring,"web_sponsor":_vm.web_sponsor}})],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }