<template>
  <div>
    <b-img class='splash' :src='pageData.acf.bannerimage'></b-img>
    <b-container>
        <template v-if="isClosed != 'Y'">
            <b-row class="qschoolEnter">
                <b-col col="12" lg="12" md="12">
                    <a :href="pageData.acf.beneath_banner_image_link" target="_blank">
                        <img :src="pageData.acf.beneath_banner_image">
                    </a>
                </b-col>
            </b-row>
            <div class="">
                <div class="q-school-app">
                <div class="row">
                    <div class="col-md-6">
                    <div class="EnterNow">To enter <span class="qschoolYear"> Q-School</span> download the LET Tour App</div>
                    </div>
                    <div class="col-md-6">
                    <div class="row q-schoolApp-block">
                        <div class="col-md-6">
                        <a href="https://apps.apple.com/us/app/ladies-european-tour/id419334047" target="_blank">
                            <div id="apple"></div>
                        </a>
                        </div>
                        <div class="col-md-6">
                        <a href="https://play.google.com/store/apps/details?id=com.ladies.european.tour&hl=en_GB&gl=US" target="_blank">
                            <div id="google"></div>
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="paddingBlock">
                <div class="row blockAppPic">
                    <div class="blockApp col-md-2">
                    <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2024/07/LET-1.png">
                    <div class="instructBlock">
                        <span class="textBlock">
                        To enter Q-School download the LET Tour App
                        </span>
                    </div> 
                    </div>
                    <div class="blockApp col-md-2">
                    <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2024/07/LET-2.png">
                    <div class="instructBlock">
                        <span class="textBlock">
                            Please press on the profile icon in the top right hand corner
                        </span>
                    </div> 
                    </div>
                    <div class="blockApp col-md-2">
                    <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2024/07/LET-3.png">
                    <div class="instructBlock">
                        <span class="textBlock">
                            Select the players tab on the right and press the "Create New Account" button
                        </span>
                    </div> 
                    </div>
                    <div class="blockApp col-md-2">
                    <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2024/07/LEt-4.png">
                    <div class="instructBlock">
                        <span class="textBlock">
                            Please press "Register for Q-School"
                        </span>
                    </div> 
                    </div>
                    <div class="blockApp col-md-2">
                    <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2024/07/LET-5.png">
                    <div class="instructBlock">
                        <span class="textBlock">
                            Run through the wizard and complete registration
                        </span>
                    </div> 
                    </div>
                    <div class="blockApp col-md-2">
                        <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2024/07/LET-6.png">
                        <div class="instructBlock">
                            <span class="textBlock">
                                Go to schedule and press "Q-School"
                            </span>
                        </div> 
                    </div>
                    <div class="blockApp col-md-2">
                        <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2024/07/LET-7.png">
                        <div class="instructBlock">
                            <span class="textBlock">
                                Enter and pay
                            </span>
                        </div> 
                    </div>
                </div>
            </div>
        </template>
        <div class="schedule-dropdown">
            <b-form-select v-model="Year" class="schedule-dropdown" v-on:change="changeYear(Year)">
                <b-form-select-option 
                    class="opt-arrow"
                    v-for="(value, index) in seasons.seasons.seasons_entry.slice().reverse()"
                    :key="index"
                    :value="value.code"
                    v-show="value.code > 2017"
                >{{value.desc}}
                </b-form-select-option>
            </b-form-select>
        </div>
        <b-row v-if="scheduleTable == ''">
            <p class="textNodata">{{Text}}</p>
        </b-row>
        <b-row class="mobileQ-school">
            <!-- This is for schedule -->
            <b-col col="12" lg="12" md="12" v-for="(value, index) in scheduleTable" :key="index">
                <div v-if="value.class_group == 'PRE' || value.class_group == 'QSC'">
                    <div class="playerprofile-blockList">
                        <b-link :to="'/tournaments-information/' + value.code + '/' + Year + '/qsc'">
                            <h3 class="tour-school-tag">{{value.short_name}}</h3>
                        </b-link>
                    </div>
                    <table class="table table-striped tourschool-table">
                        <thead>
                            <tr class="PlayerProfile-title">
                                <th class="">Venue</th>
                                <th class="">Rounds 1 - {{value.rounds}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="playerslistodd">					
                                <td class="table-pos courseNameTable">
                                    <b-link class="qschoolLink" :to="'/tournaments-information/' + value.code + '/' + Year+ '/qsc'">
                                        <span class="pos-oom">{{value.course}}</span>
                                    </b-link>
                                </td>
                                <td>
                                    {{value.dates}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>
        <b-row class="qschoolEnter classAdded">
            <b-col col="12" lg="4" md="12">
                <a :href="pageData.acf.imageonelink" target="_blank">
                    <img :src="pageData.acf.imageone">
                </a>
            </b-col>
            <b-col col="12" lg="4" md="12">
                <a :href="pageData.acf.imagetwolink" target="_blank">
                    <img :src="pageData.acf.imagetwo">
                </a>
            </b-col>
            <b-col col="12" lg="4" md="12">
                <a :href="pageData.acf.imagethreelink" target="_blank">
                    <img :src="pageData.acf.imagethree">
                </a>
            </b-col>
        </b-row>
        <b-row class="qschoolEnter">
            <b-col col="12" lg="12" md="12">
                <p v-html="pageData.acf.all_comps"></p>
            </b-col>
        </b-row>
    </b-container>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "q-school",
  props:['config', 'season'],
  data() {
    return {
        Year: this.$route.query.id,
        pageData: [],
        scheduleData: [],
        scheduleTable: [],
        newdata: [],
        seasons: [],
        cache: '',
        isClosed:'',
        Text: ''
    };
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/" +
          '136' + "?randomadd=" +
              new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.pageData = response.data;
        if (this.Year > '2020') {
            return axios.get("https://api.euro.ocs-software.com/let/cache/qsc/" + this.Year  + "/tmticx?randomadd=" +
                new Date().getTime()
            );   
        } else {
            return axios.get(process.env.VUE_APP_TIC_BASE + this.Year  + "/tmticx?randomadd=" +
                new Date().getTime()
            );  
        }
      })
      .then((response) => {
        this.scheduleData = response.data;
        if (response.data.tournaments == '') {
            console.log("error")
            this.Text = "No data available"
        } else {
            this.Text = ''
            this.scheduleTable = response.data.tournaments.tournaments_entry;
            for (let i = 0; i < this.scheduleTable.length; i++) {
                console.log(this.scheduleTable[i].is_closed);
                this.isClosed = this.scheduleTable[i].is_closed
                console.log("this.isClosed")
                console.log(this.isClosed)
            }   
        }
        return axios.get(
          process.env.VUE_APP_TIC_BASE+"tmticx?field=Y&fields=seasons&fields=tm_params.season_code&randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.seasons = response.data;
        this.newdata = response.data;
      })
  },
  methods: {
    changeYear: function(Year) {
        if (this.Year > '2020') {
            this.cache = 'qsc'
            console.log("this.cache")
            console.log(this.cache)
        } else {
            this.cache = 'let'
            console.log("this.cache")
            console.log(this.cache)
        }
      return (
        axios
          .get("https://api.euro.ocs-software.com/let/cache/" + this.cache + "/" + Year  + "/tmticx?randomadd=" +
            new Date().getTime()
          )
          .then((response) => {
            this.scheduleTable = response.data.tournaments.tournaments_entry;
          })
      );
    },
  }
};
</script>

<style scoped>
.textNodata {
    font-size: 14pt;
    padding-left: 15px;
}
.schedule-dropdown {
  margin-left: 10px;
  margin-top: 50px;
}
::v-deep .schedule-dropdown > select {
  background-color: #015699;
}
::v-deep .custom-select {
  width: 300px;
  -webkit-appearance: none;
  /* background-color: #015699; */
  border: none;
  border-radius: 0;
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  top: -48px;
  position: relative;
  left: -20px;
  text-align: left;
  height: 42px;
  background: #fff url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/DownArrow.png') right 0.75rem center/12px 7px no-repeat;
}
::v-deep a.qschoolLink {
    color: #212529;
}
::v-deep a.qschoolLink:hover {
    color: #015699; 
    text-decoration: none;
}
::v-deep .btn-secondary:hover {
    color: #015699;
    background-color: transparent;
    border-color: #545b62;
}
.paddingBlock {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 100px;
    margin-bottom: 50px;
}
span.textBlock {
    text-align: center;
    color: #212529;
    font-size: 14px;
    line-height: 1.4!important;
    display: block;
    margin-top: 20px;
    font-family: arial;
}
::v-deep .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    float: right;
    top: 9px;
    position: relative;
}
::v-deep .dropdown {
    width: 300px;
    /* float: right; */
}
::v-deep button {
    color: white;
    background-color: #015699;
    border-radius: 0;
    text-align: left;
    padding: 10px;
}
#apple {
    background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/ASB.png');
    height: 75px;
    z-index: 9999;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#apple:hover {
    background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/ASW.png');
}
#google {
    background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/GPB.png');
    height: 75px;
    z-index: 9999;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#google:hover {
    background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/GPW.png');
}
.q-schoolApp-block {
    text-align: center;
    /* padding-right: 25px; */
    padding-right: 40px;
    padding-top: 15px;
}
span.qschoolYear {
    font-weight: bold;
}
.EnterNow {
    color: #fff;
    margin: 20px;
    font-size: 15pt;
    padding-left: 25px;
}
.q-school-app {
    background-color: #0056bb;
    padding-top: 15px;
    padding-bottom: 20px;
}
.topRow {
    margin-bottom: 30px;
    width: 780px;
    margin: auto;
}
::v-deep button#__BVID__113__BV_toggle_ {
    width: 300px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #015699;
    border: none;
    border-radius: 0;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
}
::v-deep button#__BVID__1067__BV_toggle_ {
    width: 300px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #015699;
    border: none;
    border-radius: 0;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
}
td.courseNameTable {
    width: 50%;
}
.PlayerProfile-title > th {
    font-weight: 400;
    border-top: 0px solid #dee2e6;
}
span.pos-oom {
    margin-left: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
    font-size: 1rem;
}
table.tourschool-table {
    text-transform: capitalize;
    margin-top: -1px;
    margin-bottom: 0;
}
.PlayerProfile-title {
    background-color: #42a7c6;
    color: #fff;
}
.playerprofile-blockList {
    background-color: #01477b;
    padding: 0 0 0 10px;
    margin-top: 3px;
    color: #fff;
}
h3.tour-school-tag {
    color: #fff;
    padding: 15px 0 15px 0;
    font-size: 13pt;
    margin-top: 0;
    margin-bottom: 0;
}
::v-deep img.size-full.wp-image-175195 {
    height: 300px;
}
::v-deep  img.size-medium.wp-image-174810.alignnone {
    width: 300px;
}
::v-deep  img.wp-image-174805.alignleft {
    width: 300px;
}
::v-deep  img.size-medium.wp-image-174806.alignright {
    width: 300px;
}
::v-deep  img.alignnone.size-medium.wp-image-174803 {
    width: 300px;
}
::v-deep  img.alignnone.size-medium.wp-image-174801 {
    width: 300px;
}
.qschoolEnter {
    padding-top: 50px;
    padding-bottom: 50px;
}
::v-deep img{
  width: 100%;
}

@media only screen and (max-width: 768px) {
    ::v-deep .custom-select {
    width: 100%;
    -webkit-appearance: none;
    /* background-color: #015699; */
    border: none;
    border-radius: 0;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    top: -48px;
    position: relative;
    left: -20px;
    text-align: left;
    height: 42px;
    background: #fff url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/DownArrow.png') right 0.75rem center/12px 7px no-repeat;
    }
    .mobileQ-school {
        display: block;
    }
    .mobileQ-school > div {
        margin-bottom: 15px;
    }
    .dropdown {
        width: 100%;
    }
    .qschoolEnter {
        padding-top: 50px;
        padding-bottom: 50px;
        display: block;
    }
    .qschoolEnter > div {
        margin-bottom: 15px;
    }
    .blockApp.col-md-2 {
        width: 50%;
    }
}
</style>
