<template>
  <div>
    <!-- <div class="AdPadTest">
      <img class="AdPadTestImage" src="https://tpc.googlesyndication.com/simgad/5675076390026035573">
    </div> -->
    <Ads
      class="mobHide"
      :page="'home'"
      :adNum="'top'"
      :mob="false"
      :side="false"
    />
    <Ads
      class="mobShow"
      :page="'home'"
      :adNum="'top'"
      :mob="true"
      :side="false"
    />
    <!-- <div :class="{ spacer: takeOver === true }"></div> -->
    <div class="home" :class="{ container: takeOver === true, nopadMod :takeOver === true   }">
      <div class="homeBottom">
        <div id="slider">
          <Slider
            :posts="posts"
            :season="season"
            :course="course"
            :report="report"
            :title="title"
            :takeover="takeOver"
            :config="config"
            :page="'home'"
            :adNum="'top'"
            :forceScoring="forceScoring"
          />
        </div>
        <b-tabs align="center" class="smallNav">
          <Ads
            class="mobHide secondAd"
            :page="'home'"
            :adNum="'second'"
            :mob="false"
            :side="false"
          />
          <Ads
            class="mobShow"
            :page="'home'"
            :adNum="'second'"
            :mob="true"
            :side="false"
          />
          <b-tab active>
            <div id="feature">
              <Post />
            </div>
          </b-tab>
          <b-tab>
            <b-container id="feature">
              <div class="SeeAllVideos">
                <b-link :to="'/video?id='">
                  <b-button class="socialTitle videosTitle"
                    >See All Videos</b-button
                  >
                </b-link>
              </div>
              <YouTube :id="config.VUE_APP_YOUTUBE_PLAYLIST" />
            </b-container>
          </b-tab>
          <b-tab>
            <div id="flickr">
              <Flickr :config="config" />
            </div>
          </b-tab>
        </b-tabs>
        <Ads
          class="mobHide homepage-ad-desktop"
          :page="'home'"
          :adNum="'third'"
          :mob="false"
          :white="true"
          :side="false"
        />
        <div id="banner">
          <b-container>
            <b-row class="adBlock">
              <b-col lg="6" md="6" cols="12">
                <b-link href="https://www.golfchic.co.uk/" target="_blank">
                  <b-img
                    class="siteBanner"
                    :src="
                      config.VUE_APP_WP_DIGITAL_OCEAN + '2023/07/GC-banner.jpg'
                    "
                  ></b-img>
                </b-link>
              </b-col>
              <b-col lg="6" md="6" cols="12" class="test">
                <b-nav-item class="BL" to="news-letter-2020-signup">
                  <b-img
                    class="siteBanner "
                    :src="
                      config.VUE_APP_WP_DIGITAL_OCEAN +
                        '2020/05/LET20-WEBADST-Generic-Newsletter.jpg'
                    "
                  ></b-img>
                </b-nav-item>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <OomSlider
                  :season="season"
                  :oomKey="config.VUE_APP_OOM"
                  :oom="oom"
                  :config="config"
                />
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div id="upComing">
          <!-- <UpComingTours :config="config"/> -->
        </div>
        <div id="flickr">
          <!-- <div style="background-color: white">
          <b-container style="background-color: white">
            <b-link href="https://www.flickr.com/photos/ladieseuropeantour/" target="_blank">
              <b-button v-if="this.$route.name === 'home' " class="allNews">
                See All Photos
              </b-button>
            </b-link>
          </b-container>
          </div> -->
          <Flickr :config="config" />
        </div>
        <Ads
          class="mobHide homepage-ad-desktop"
          :page="'home'"
          :adNum="'foot'"
          :mob="false"
          :white="true"
          :side="false"
        />
        <Ads
          class="mobShow"
          :page="'home'"
          :adNum="'foot'"
          :mob="true"
          :white="true"
          :side="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Ads from "@/components/ads.vue";
import Post from "@/components/posts.vue";
import Slider from "@/components/slider.vue";
// import Twitter from "@/components/twitterFeed.vue";
// import InstaFeed from "@/components/instaFeed.vue";
// import FaceBook from "@/components/faceBook.vue";
import OomSlider from "@/components/oomSlider.vue";
// import UpComingTours from "@/components/upComingTours.vue";
import Flickr from "@/components/flickr.vue";
import YouTube from "@/components/youTube.vue";
import axios from "axios";

export default {
  name: "home",
  props: ["course", "season", "report", "title", "config", "currentReport"],
  components: {
    Ads,
    Slider,
    Post,
    // Twitter,
    // InstaFeed,
    // FaceBook,
    OomSlider,
    // UpComingTours,
    Flickr,
    YouTube
  }, // Everything below on every /view/<Page Name>.vue for meta info and all ACF data
  data: function() {
    return {
      description: [], //Meta Description
      homepage: true,
      posts:
        this.config.VUE_APP_WPAPI_URL +
        "wp/v2/posts?categories=6&randomadd=" +
        new Date().getTime(),
      sponsor: this.config.VUE_APP_WPAPI_URL + "wp/v2/",
      componentKey: 0,
      oom: [],
      wpHome: [],
      forceScoring: []
    };
  },
  computed: {
    takeOver: function(take) {
      if (this.wpHome === false) take = false;
      else take = true;
      return take;
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_WPAPI_URL + "wp/v2/pages/196369?randomadd=" +
        new Date().getTime())
      .then(response => {
        this.wpHome = response.data.acf.takeover_page;
        this.forceScoring = response.data.acf.force_scoring_widget;
        this.$emit("takeover", { takeover: this.wpHome });
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            this.season +
            "/" +
            this.season +
            "-ooms-oom-" +
            process.env.VUE_APP_OOM +
            ".json?randomadd=" +
            new Date().getTime()
        );
      })
      .then(response => {
        this.oom = response.data;
      });
  }
};
</script>
<style scoped>
.TestClass {
  color: #01477b;
}
::v-deep .allNews:hover {
  float: right;
  background-color: #015699;
  border-radius: 0;
  padding: 11px 30px;
  margin-bottom: 8px;
  border-color: #015699;
}
div#flickr {
  padding-top: 20px;
}
.smallNav > div {
  height: 120px;
}
.homeBottom {
  margin-bottom: -30px;
}
.SeeAllVideos {
  margin-bottom: 50px;
}
::v-deep .smallNav > div > ul {
  height: 105px;
}
.allNews {
  float: right;
  background-color: #01477b;
  border-radius: 0;
  padding: 11px 30px;
  margin-top: -48px;
  border-color: #01477b;
}
.adBlock {
  /* margin-bottom: 40px; */
  margin-bottom: 0px;
  margin-top: -5px;
}
::v-deep .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent !important;
}
.videosTitle {
  margin-right: 30px;
  margin-bottom: 2em;
}
.mobHide.secondAd {
  margin-top: 2em;
  margin-bottom: -3em;
}
.home.container {
  padding-top: 20px;
}
.spacer {
  height: 150px;
}
.smallNav {
  width: 100%;
  left: 0;
  margin: 0;
  /* background-color: white; */
}
.social {
  background-color: #01477b;
  border-radius: 0;
  padding: 11px 30px;
}
.socialTitle {
  float: right;
  background-color: #01477b;
  border-radius: 0;
  padding: 11px 30px;
}
#banner {
  padding: 100px 0;
  /* background-color: white; */
  padding-top: 0px;
}

.siteBanner {
  width: 100%;
}
.home {
  /* background-color: #f1f1f1; */
}
#feature {
  /* padding: 50px 0 100px 0; */
  padding: 50px 0 7px 0;
  /* background-color: white; */
}
#social {
  padding: 100px;
}
.homepage-ad-desktop {
  margin-bottom: 50px;
}
::v-deep .nav-item {
  display: flex;
  align-items: center;
  padding-top: 7px;
}
::v-deep .col-md-10 {
  display: flex;
  align-items: center;
  padding-top: 7px;
}
::v-deep .fa-instagram {
  color: #cc4666;
}
.title {
  color: #133f7b;
}
::v-deep .nav-tabs > li:nth-child(1) {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/News_White_2023.png);
  width: 80px;
  height: 120px;
  background-size: 75px;
  background-repeat: no-repeat;
}
::v-deep .nav-tabs > li:nth-child(1):hover {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/News_Blue_2023.png);
}
::v-deep .nav-tabs > li:nth-child(2) {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/Videos_White_2023.png);
  width: 80px;
  height: 120px;
  background-size: 75px;
  background-repeat: no-repeat;
}
::v-deep .nav-tabs > li:nth-child(2):hover {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/Videos_Blue_2023.png);
}
::v-deep .nav-tabs > li:nth-child(3) {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/Photos_White_2023.png);
  width: 80px;
  height: 120px;
  background-size: 75px;
  background-repeat: no-repeat;
}
::v-deep .nav-tabs > li:nth-child(3):hover {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/09/Photos_Blue_2023.png);
}
::v-deep .nav-tabs > .nav-item > .nav-link {
  width: 100%;
  height: 100%;
}
::v-deep .nav-tabs > .nav-item > .nav-link.active {
  background-color: transparent;
  border-color: transparent;
}
::v-deep .nav-tabs {
  background-color: #015699;
  border-bottom: 0px solid #dee2e6;
}
.BL {
  padding: 0;
}
.BL > .nav-link {
  padding: 0;
}
.mobHide {
  display: block;
}
.mobShow {
  display: none;
}
.AdPadTest {
  padding: 30px 0px;
  min-width: 970px; 
  min-height: 50px;
}
.AdPadTestImage {
  margin:auto; 
  display:block; 
  width: 970px; 
}
@media only screen and (max-width: 768px) {
  .home.container.nopadMod {
    padding: 0px;
    background-color: #fff;
  }
  .mobHide {
    display: none;
  }
  .mobShow {
    display: block;
  }
  ::v-deep #oomSlider {
    padding-top: 380px;
    margin-top: 10em;
  }
}

@media only screen and (max-width: 425px) {
    #social {
        padding: 10px;
    }
    .siteBanner {
        padding: 10px;
    }
    #banner {
        padding: 40px 0;
        /* background-color: white; */
        padding-top: 0px;
    }
    #feature {
        padding: 50px 0 50px 0;
    }
    #oomSlider {
        padding-top: 470px;
    }
    .AdPadTest {
        padding: 15px 0px;
        min-width: 320px; 
        min-height: 50px;
    }
    .AdPadTestImage {
        margin:auto; 
        display:block; 
        width: 320px; 
    }
}
@media only screen and (max-width: 375px) {
  #oomSlider {
    margin-top: 10em;
  }
}
</style>
