<template>
  <div>
    <span v-if="this.$route.name === 'home' || this.$route.name === 'Tournament'">
      <div id="fb-root"></div>
      <script
        type="application/javascript"
        async
        defer
        crossorigin="anonymous"
        src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v5.0"
      ></script>
      <div
        class="fb-page"
        :data-href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME+'/?rf=735464763131634'"
        data-tabs="timeline"
        data-width=""
        data-height="571"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          :cite="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME+'/?rf=735464763131634'"
          class="fb-xfbml-parse-ignore"
        >
          <a
            :href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME+'/?rf=735464763131634'"
            >Ladies European Tour</a
          >
        </blockquote>
      </div>
    </span>
    <span v-if="this.$route.name === 'team-europe'">
      <div id="fb-root"></div>
      <script
      type="application/javascript"
        async
        defer
        crossorigin="anonymous"
        src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v8.0"
        nonce="v9Dnn7w2"
      ></script>
      <div
        class="fb-page facebookdesk"
        :data-href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_SOL"
        data-tabs="timeline"
        data-width=""
        data-height="571"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          :cite="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_SOL"
          class="fb-xfbml-parse-ignore"
        >
          <a :href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_SOL"
            >Solheim Cup Team Europe</a
          >
        </blockquote>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  name: "twitterFeed",
  props: ["develop", 'config'],
};
</script>

<style scoped>
#twitter-widget-0 {
  height: 500px;
}
</style>
