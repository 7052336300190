<template>
  <div>
    <div>
      <b-img
        class="splash"
        :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/01/stats-1.png'"
      ></b-img>
    </div>
    <b-container>
      <div class="schedule_block">
        <b-form-select v-model="stat" class="schedule-dropdown" v-on:change="pickStat(stat)">
          <b-form-select-option 
            class="opt-arrow"
            v-for="(value, index) in FullStats"
            :key="index"
            :value="value.code"
          >{{value.full_name}}</b-form-select-option>
        </b-form-select>
      </div>
      <div class="schedule_block">
        <b-form-select v-model="id" class="schedule-dropdown" v-on:change="changeYear(id)">
          <b-form-select-option 
            class="opt-arrow"
            v-for="(value, index) in seasons.slice().reverse()"
            :key="index"
            :value="value.code"
          >{{value.desc}}</b-form-select-option>
        </b-form-select>
      </div>
      <div class="schedule_block">
        <b-link :to="'/tournaments-information/' + tourncode +  '/' + id" class="bkToTourn">
            <div class="btn dLoad btn-secondary">
                <span class="backText"> 
                    <span class="leftArrowBackSpan">
                        <img class="leftArrowBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2024/03/pngfind.com-left-png-5173378.png">
                    </span>

                    <p class="bkButton">Back to Tournament</p>
                </span>
            </div>
        </b-link>
      </div>
      <!-- <b-link :to="'/tournaments-information/' + this.$route.query.tournStatCode + '/' + id"> -->
      <div>
        <StatsTable :id='id' :stat='stat' :data='stats' :config="config"/>
      </div>
    </b-container>
  </div>
</template>

<script>
import StatsTable from "@/components/statsTable.vue";
import axios from "axios";
export default {
  name: "fullStats",
  props:['config'],
  components:{
    StatsTable
  },
  data() {
    return {
      stats: [],
      seasons: [],
      stat: this.$route.query.statdata,
      id: this.$route.query.id,
      tourncode: this.$route.query.tournStatCode,
      tournName: this.$route.query.tourn,
      FullStats: []
      // statCodes: [
      //   { value: "Z1", title: "DRIVING ACCURACY" },
      //   { value: "Z2", title: "DRIVING DISTANCE" },
      //   { value: "Z3", title: "GREEN IN REGULATION" },
      //   { value: "Z4", title: "SAND SAVES" },
      //   { value: "Z5", title: "PUTTS PER ROUND" },
      //   { value: "X1", title: "STROKE AVERAGE" },
      //   { value: "X4", title: "EAGLES" },
      //   { value: "X6", title: "BIRDIES" },
      //   { value: "U6", title: "STROKES GAINED AVERAGE" },
      //   { value: "U7", title: "STROKES GAINED DRIVING AVERAGE" },
      //   { value: "U8", title: "STROKES GAINED APPROACH AVERAGE" },
      //   { value: "U9", title: "STROKES GAINED SHORT GAME AVERAGE" },
      //   { value: "UA", title: "STROKES GAINED PUTTING AVERAGE" },
      // ],
    };
  },
  methods: {
    pickStat: function(stat) {
      return (
        axios
        .get(
            process.env.VUE_APP_TIC_BASE +
            this.id  +
            "/" +
            this.id  +
            '-'
            +
            this.$route.query.tournStatCode +
            "-stats-" +
            stat +
            ".json?randomadd=" +
            new Date().getTime()
        )
          .then((response) => (this.stats = response.data))
      );
    },
    changeYear: function(id) {
      return (
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
            id  +
            "/" +
            id  +
            '-'
            +
            this.$route.query.tournStatCode +
            "-stats-" +
            this.stat +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then((response) => (this.stats = response.data))
      );
    },
  },
  computed: {
    currentStat: function() {
      return this.statCodes.filter((stat) => !stat.value.indexOf(this.stat));
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE +
          this.$route.query.id +
          "/" +
          this.$route.query.id +
          '-'
          +
          this.$route.query.tournStatCode +
          "-stats-" +
          this.stat +
          ".json?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.stats = response.data;
        return axios.get(
          process.env.VUE_APP_TIC_BASE + this.$route.query.id +"/tmticx?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.FullStats = response.data.stats.stats_entry;
        this.seasons = response.data.seasons.seasons_entry;
      });
  },
};
</script>

<style scoped>
.bkToTourn {
    top: -42px;
    position: relative;
}
p.bkButton {
    font-size: 1rem;
    text-transform: uppercase;
}
img.leftArrowBack {
    width: 10px;
    float: left;
    position: relative;
    top: -2px;
    left: -18px;
}
::v-deep .btn {
    background-color: #015699;
    border: none;
    color: #fff;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 10pt;
    width: 300px;
    height: 42px;
    line-height: 1;
    float: right;
    border-radius: 0;
}
::v-deep .btn:hover{
    background-color: #43a7c6;
    border: none;
    color: #fff;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 10pt;
    width: 300px;
    height: 42px;
    line-height: 1;
    float: right;
    border-radius: 0;
}
::v-deep .schedule_block {
  display: contents;
}
::v-deep .schedule_block > select {
  background-color: #015699!important;
}
::v-deep select.schedule-dropdown {
  width: 300px;
  border: none;
  border-radius: 0;
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  top: -42px;
  position: relative;
  left: -20px;
  text-align: left;
  height: 42px;
  background: #fff url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/DownArrow.png') right 0.75rem center/12px 7px no-repeat;
  margin-left: 20px;
}
.splash {
  width: 100%;
}
::v-deep .year > .btn {
  width: 300px;
  -webkit-appearance: none;
  background: #015699;
  border: none;
  border-radius: 0;
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  top: -44px;
  position: relative;
  left: -20px;
  text-align: left;
}
::v-deep .year > .btn::after {
  float: right;
  margin-top: 12px;
}
.dropdown{
  padding: 0 10px;
}

@media only screen and (max-width: 768px) { 
  ::v-deep .btn {
    background-color: #015699;
    border: none;
    color: #fff;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 10pt;
    width: 100%;
    height: 39px;
    line-height: 1;
    float: none;
    border-radius: 0;
    margin-top: 40px;
  }
    ::v-deep .btn:hover {
    background-color: #43a7c6;
    border: none;
    color: #fff;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 10pt;
    width: 100%;
    height: 39px;
    line-height: 1;
    float: none;
    border-radius: 0;
    margin-top: 40px;
  }
  ::v-deep .schedule_block {
    display: block;
    margin-top: 20px;
  }
  select.schedule-dropdown {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    top: 0px;
    position: relative;
    left: -20px;
    text-align: left;
    height: 42px;
    background: #fff url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/DownArrow.png) right 0.75rem center/12px 7px no-repeat;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 425px) { 
  ::v-deep.year > .btn {
    width: 300px;
    -webkit-appearance: none;
    background: #015699;
    border: none;
    border-radius: 0;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    top: 20px;
    position: relative;
    left: -10px;
    text-align: left;
    margin-bottom: 1em;
  }
  .TableStat {
    margin-top: 2em;
  }
}
</style>
