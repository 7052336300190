<template>
  <div>
    <div
      v-if="side === false"
      style="text-align:center;"
      :class="{ 
        white: white === true,
      }"
    >
      <!-- All Home Desktop Banners -->

      <!-- Top -->

      <template v-if="page === 'home' && adNum === 'top' && mob === false">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LET-Homepage-TopBanner-970x90",
                [[970, 90]],
                "div-gpt-ad-1623251327126-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LET-Homepage-TopBanner-970x90 -->
        <div
          id="div-gpt-ad-1623251327126-0"
          style="min-width: 320px; min-height: 50px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623251327126-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>

      <!-- second -->

      <template v-if="page === 'home' && adNum === 'second' && mob === false">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LET-Homepage-BannerTwo-728x90",
                [[728, 90]],
                "div-gpt-ad-1623252231294-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LET-Homepage-BannerTwo-728x90 -->
        <div
          id="div-gpt-ad-1623252231294-0"
          style="min-width: 320px; min-height: 50px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623252231294-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>

      <!-- third -->

      <template v-if="page === 'home' && adNum === 'third' && mob === false">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LET-Homepage-BannerThree-728x90",
                [728, 90],
                "div-gpt-ad-1623252526283-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
      			googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LET-Homepage-BannerThree-728x90 -->
        <div
          id="div-gpt-ad-1623252526283-0"
          style="min-width: 728px; min-height: 90px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623252526283-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>

      <!-- foot -->

      <template v-if="page === 'home' && adNum === 'foot' && mob === false">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/footer_ad_homepage_728x90",
                [728, 90],
                "div-gpt-ad-1623254295748-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/footer_ad_homepage_728x90 -->
        <div
          id="div-gpt-ad-1623254295748-0"
          style="min-width: 728px; min-height: 90px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623254295748-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>

      <!-- All Home Mobile Banners -->

      <!-- Top -->

      <template v-if="page === 'home' && adNum === 'top' && mob === true">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LETMobile-HomepageTopBanner-320x50",
                [320, 50],
                "div-gpt-ad-1623310713043-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LETMobile-HomepageTopBanner-320x50 -->
        <div
          id="div-gpt-ad-1623310713043-0"
          style="min-width: 320px; min-height: 50px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623310713043-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>

      <!-- Second -->

      <template v-if="page === 'home' && adNum === 'second' && mob === true">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LETMobile-HomepageSecondBanner-320x50",
                [320, 50],
                "div-gpt-ad-1623311075213-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LETMobile-HomepageSecondBanner-320x50 -->
        <div
          id="div-gpt-ad-1623311075213-0"
          style="min-width: 320px; min-height: 50px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623311075213-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>

      <template v-if="page === 'home' && adNum === 'foot' && mob === true">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LETMobile-HomepageFooterBanner-320x50",
                [320, 50],
                "div-gpt-ad-1623311338589-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LETMobile-HomepageFooterBanner-320x50 -->
        <div
          id="div-gpt-ad-1623311338589-0"
          style="min-width: 320px; min-height: 50px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623311338589-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>

      <!-- All Home RTCDS Banners -->

      <!-- Top -->

      <template v-if="page === 'rank' && adNum === 'rtcds' && mob === false">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LET-RTCDSTopBanner-970x90",
                [970, 90],
                "div-gpt-ad-1623311865070-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LET-RTCDSTopBanner-970x90 -->
        <div
          id="div-gpt-ad-1623311865070-0"
          style="min-width: 970px; min-height: 90px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623311865070-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>

      <template v-if="page === 'rank' && adNum === 'rtcds' && mob === true">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LET21_RTCDS_MOBILE_320x50three",
                [320, 50],
                "div-gpt-ad-1623312336787-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LET21_RTCDS_MOBILE_320x50three -->
        <div
          id="div-gpt-ad-1623312336787-0"
          style="min-width: 320px; min-height: 50px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623312336787-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>

      <!-- All Home Solheim Banners -->

      <!-- Top -->

      <template v-if="page === 'solheim' && adNum === 'top' && mob === false">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LET-SolheimCupTopBanner-970x90",
                [970, 90],
                "div-gpt-ad-1623314140038-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LET-SolheimCupTopBanner-970x90 -->
        <div
          id="div-gpt-ad-1623314140038-0"
          style="min-width: 970px; min-height: 90px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623314140038-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>

      <!-- Foot -->

      <template v-if="page === 'solheim' && adNum === 'foot' && mob === false">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LET-SolheimCupFooterBanner-728x90",
                [728, 90],
                "div-gpt-ad-1623313891989-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LET-SolheimCupFooterBanner-728x90 -->
        <div
          id="div-gpt-ad-1623313891989-0"
          style="min-width: 728px; min-height: 90px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623313891989-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>

      <!-- All Tourn Banners -->

      <!-- Top -->

      <template v-if="page === 'tour' && adNum === 'top' && mob === false">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LET-TournamentsPageTopBanner-970x90",
                [[970, 90]],
                "div-gpt-ad-1623314465443-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LET-TournamentsPageTopBanner-970x90 -->
        <div
          id="div-gpt-ad-1623314465443-0"
          style="min-width: 970px; min-height: 90px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623314465443-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>
      <template v-if="page === 'tour' && adNum === 'foot' && mob === false">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LET-TournamentsPageFooterBanner-728x90",
                [728, 90],
                "div-gpt-ad-1623315410078-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LET-TournamentsPageFooterBanner-728x90 -->
        <div
          id="div-gpt-ad-1623315410078-0"
          style="min-width: 728px; min-height: 90px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623315410078-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>

      <!-- All Report Banners -->

      <!-- Top -->

      <template v-if="page === 'report' && adNum === 'top' && mob === false">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LET-LETMoneyListTopBanner-970x90",
                [970, 90],
                "div-gpt-ad-1623321490095-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      <!-- googletag.pubads().collapseEmptyDivs(); -->
          });
        </script>
        <!-- /22264165340/LET-LETMoneyListTopBanner-970x90 -->
        <div
          id="div-gpt-ad-1623321490095-0"
          style="min-width: 970px; min-height: 90px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623321490095-0");
              googletag.pubads().refresh();
			        <!-- googletag.pubads().collapseEmptyDivs(); -->
            });
          </script>
        </div>
      </template>

      <!-- News -->

      <!-- Top -->

      <template v-if="page === 'newsStory' && adNum === 'top' && mob === false">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LET-NewsPagesTopBanner-970x90",
                [970, 90],
                "div-gpt-ad-1623326129864-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LET-NewsPagesTopBanner-970x90 -->
        <div
          id="div-gpt-ad-1623326129864-0"
          style="min-width: 970px; min-height: 90px;"
          class="AdPad"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623326129864-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>
    </div>

<!-- Side Ads -->

    <div
      v-if='side === true'
      style="float:right; margin-top:-40px"
      :class="{ white: white === true , ReportAd: this.$route.name === 'reports-page'}"
      class="sideNews"
    >
      <!-- News Story -->

      <template v-if="page === 'newsStory' && adNum === 'side' && mob === false">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LET-NewsRightSideBar-300x250",
                [300, 250],
                "div-gpt-ad-1623326419850-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LET-NewsRightSideBar-300x250 -->
        <div
          id="div-gpt-ad-1623326419850-0"
          style="min-width: 300px; min-height: 250px;"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623326419850-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs(); 
            });
          </script>
        </div>
      </template>

      <!-- Reports-->

      <template v-if="page === 'report' && adNum === 'small' && mob === false">
        <script
          type="application/javascript"
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag
              .defineSlot(
                "/22264165340/LET-LiveLeaderboard-toprightbanner-300x100",
                [300, 100],
                "div-gpt-ad-1623321673433-0"
              )
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
			      googletag.pubads().collapseEmptyDivs();
          });
        </script>
        <!-- /22264165340/LET-LiveLeaderboard-toprightbanner-300x100 -->
        <div
          id="div-gpt-ad-1623321673433-0"
          style="min-width: 300px; min-height: 100px;"
        >
          <script type="application/javascript">
            googletag.cmd.push(function() {
              googletag.display("div-gpt-ad-1623321673433-0");
              googletag.pubads().refresh();
			        googletag.pubads().collapseEmptyDivs();
            });
          </script>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "ads",
  props: ["page", "adNum", "mob", "white", 'side'],
  // methods: {
  //   AdsAcive: function() {

  //   }
  // }
};
</script>

<style scoped>
.white {
  /* background-color: white; */
}
.ReportAd {
  height: auto!important;
}
.AdPad {
  padding: 30px 0px;
}
</style>